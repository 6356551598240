import styled from "styled-components";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../../utilities/constants"; 

export const Container = styled.div`
  margin: 24px 48px;
  border-radius: 12px;
  background-color: #F5FBFC;
  opacity: 1;
  min-height: calc(100vh - 72px);
  margin-bottom: 0;
  overflow-y: auto;
`;
export const Wrapper = styled.div`
  display: flex;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
`;
export const Header = styled.div`
  font-size: 24px;
  color: #000000;
`;
export const SearchInput = styled.input`
  font-size: 14px;
  outline: none;
  flex: 1;
  background: transparent;
  height: 35px;
  border: none;
`;
export const HeaderLeft = styled.div`
  display: flex;
  flex: 0.4;
`;
export const HeaderRight = styled.div`
  display: flex;
  flex: 0.6;
  justify-content: flex-end;
  align-items: center;
`;

export const AddButton = styled.button`
  padding: 10px;
  width: 150px;
  height: 40px;
  background: #7aba56 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 20px;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
`;
export const EditButton = styled.button`
  color: #7aba57;
  font-size: 14px;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-right: 15px;
`;
export const SearchButton = styled.button`
  border: none;
  background: transparent;
  cursor: pointer;
`;
export const SearchImage = styled.img`
  width: 25px;
  height: 25px;
`;
export const SearchWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  background: #ebf7fb 0% 0% no-repeat padding-box;
  border-radius: 8px;
  padding: 5px 12px;
  width: 400px;
  height: 35px;
  margin-top: 10px;
  border-style: hidden;
`;
export const ActionImage = styled.img`
  margin-right: 5px;
`;
export const ProfileImage = styled.img`
  margin-right: 10px;
`;
export const EmptyListMessage = styled.div`
  margin-top: 10px;
  padding: 20px;
  color: red;
  text-align: center;
`;
export const WrappedListValue = styled.div`
  width: 40px;
  height: 25px;
  border: 1px solid #d7eff7;
  border-radius: 5px;
  color: #0099cc;
  font-weight: bold;
  background-color: #ebf7fb;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 5px;
`;
export const EditDeleteWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`;
export const FullNameWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;
export const CaseNumber = styled.span`
    color: #000000;
    font-size: 16px;
    margin-right: 10px;
    font-weight: bold;
`;
export const UpdatedDate = styled.span`
    color: #808080;
    font-size: 14px;
`;
export const StatusText = styled.span`
  color: ${props => (props.status === 'Ongoing' ? '#7ABA56' : props.status === 'Finished' ? '#0099CC' : '#000000')};
  margin-left: 5px;
`;
export const EncountersText = styled.span`
    color: #7ABA56;
    font-size: 14px;
    margin-left: 5px;
`;
export const EncountersCount = styled.span`
    background-color: #EFF7EA;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid;
    outline: none;
    border-style: hidden;
    padding: 7px 12px;
`;