import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  AuthSlice,
  StatusSlice,
  PatientSlice,
  ConsultationSlice,
  RecordsSlice,
  AssessmentSlice,
  PatientProfileSlice,
  HemebotSlice,
  SingleRecordSlice,
  GenerateDocSlice,
  DicomFileSlice,
  RecordTypeSlice,
  UploadAudioSlice,
  StartTranscriptSlice,
  GetNotesSlice,
  RemindersSlice,
  thumbnailsSlice,
} from "./slice";

const rootReducer = combineReducers({
  auth: AuthSlice,
  status: StatusSlice,
  patient: PatientSlice,
  consultation: ConsultationSlice,
  record: RecordsSlice,
  assessment: AssessmentSlice,
  patientProfile: PatientProfileSlice,
  hemebot: HemebotSlice,
  singleRecord: SingleRecordSlice,
  generateDoc: GenerateDocSlice,
  dicomFile: DicomFileSlice,
  recordType: RecordTypeSlice,
  audioObject: UploadAudioSlice,
  startTransciptObject: StartTranscriptSlice,
  getNotesObject: GetNotesSlice,
  reminders: RemindersSlice,
  thumbnail: thumbnailsSlice,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["status"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER, 'generate-doc/fulfilled'],
        ignoredPaths: ['generateDoc.document']
      },
    }),
});

const persistor = persistStore(store);

export { persistor, store };
