import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { IBackArrow, INextArrow } from "../../../assets";
import { useDispatch, useSelector } from "react-redux";
import {
    Container,
    TopHeader,
    ArrowBackImage,
    ObservationContainer, ObservationEntry, ObservationTile, ObservationDetail,
    ObservationSelector, ObservationThumb, ObservationName, NextArrowIcon,
    ObservationInfo, ObservationNameSmall, ObservationLogoSmall, SaveButton, DateContainer
  } from "./style.components";
import { patientSelector } from "../../../store/slice/patient.slice";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import { Typography, Box } from '@mui/material';
import Button from '@mui/material/Button';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import { ShimmerPostDetails } from "react-shimmer-effects";
import { addObservationAction, getObservationsListAction, uploadRecordsAction, sendRecordsAction , getRecordsAction  } from "../../../store/actions";
import { ObservationQuestionnaireType, ObservationQuestionnaireForm } from "../../questionnaire";
import { SnackBar } from "../../../components/shared";
import { formatISO } from 'date-fns';

const theme = createTheme({
    components: {
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: '#808080', // Label color
            fontSize: '13px', // Label font size
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#D7EFF7', // Border color
                borderRadius: '12px', // Border radius
              },
              '&:hover fieldset': {
                borderColor: '#D7EFF7', // Hover Border color
              },
              '&.Mui-focused fieldset': {
                borderColor: '#D7EFF7', // Focused Border color
              },
            },
            '& .MuiInputLabel-root': {
              color: '#808080', // Override label color
              fontSize: '13px', // Override label font size
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            color: '#D7EFF7', // Slider track and thumb color
            '& .MuiSlider-thumb': {
              '&:focus, &:hover, &.Mui-active': {
                boxShadow: `0px 0px 0px 8px rgba(215, 239, 247, 0.16)`, // Custom focus/hover state
              },
            },
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: '#D7EFF7', // Radio button color when not selected
            '&.Mui-checked': {
              color: '#0099CC', // Radio button color when selected
            },
          },
        },
      },
    },
});  

const getCurrentDateTime = () => {
    const now = new Date();
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    return now.toLocaleString('en-US', options);
};
  
const AddObservation = () => {
    const navigate = useNavigate();
    const { selectedPatientId } = useSelector(patientSelector);
    const [selectedObservationIndex, setSelectedObservationIndex] = useState(1);
    const [dateTime, setDateTime] = useState(getCurrentDateTime());
    const [sugarLevel, setSugarLevel] = useState("beforeMeal");
    const [painLevel, setPainLevel] = useState(0);
    const [selectedFileName, setSelectedFileName] = useState('');
    const [dateTimeISO, setDateTimeISO] = useState(new Date());
    const [noteContent, setNoteContent] = useState('');
    const [userInputs, setUserInputs] = useState({});
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showQuestionnaireForm, setShowQuestionnaireForm] = useState(false);
    const [selectedFormType, setSelectedFormType] = useState(null);
    const [observationArray, setObservationArray] = useState([]);

    const selectedObservation = selectedObservationIndex !== null ? observationArray[selectedObservationIndex - 1] : null;

    const fetchAllObservations = useCallback(async () => {
        setIsLoading(true);
        try {
            const response = await dispatch(getObservationsListAction());
            const updatedObservations = [
                ...response?.payload?.data,
                {
                    "sub_type": "questionnaire",
                    "display_name": "Questionnaire",
                    "icon": "https://heme-content-files.s3.ap-south-1.amazonaws.com/track-feature/observation-type/svg/medical-form-icon.svg"
                }
            ];
            setObservationArray(updatedObservations);
        } catch (error) {
            console.error("Error fetching observations:", error);
        } finally {
            setIsLoading(false);
        }
    }, [dispatch]);

    useEffect(() => {
        fetchAllObservations();
      }, [fetchAllObservations]);
    
    if (isLoading) {
        return <Container><ShimmerPostDetails card cta variant="SIMPLE" /></Container>;
    }
    
    const handleFileChange = (event) => {
      const file = event.target.files[0];
      if (file) {
          setSelectedFileName(file);
      }
    };

    const handleInputChange = (event, observationCode) => {
        const newValue = event.target.value;
        setUserInputs(prevInputs => ({
            ...prevInputs,
            [observationCode]: newValue,
        }));
    };

    const handleSelectFormType = ({ formId, formType }) => {
        setSelectedFormType({ formId, formType });
        setShowQuestionnaireForm(true);
    };

    const handleBackToTypeSelection = () => {
        setShowQuestionnaireForm(false); // Assuming this state controls the toggle
        setSelectedFormType(null); // Resetting the selected form type
    };

    const getComponentCodeForSugarLevel = (sugarLevelValue) => {
        // Assume "beforeMeal" maps to "Before meal sugar" and "afterMeal" maps to "After meal sugar"
        const beforeMealComponent = selectedObservation.codes.component.find(c => c.display_name.toLowerCase().includes("before meal"));
        const afterMealComponent = selectedObservation.codes.component.find(c => c.display_name.toLowerCase().includes("after meal"));
    
        return sugarLevelValue === "beforeMeal" ? beforeMealComponent.code : afterMealComponent.code;
    };

    const findComponentCode = (componentDisplayName) => {
        const component = selectedObservation?.codes.component.find(c => c.display_name.includes(componentDisplayName));
        return component ? component.code : null;
    };

    const handleObservationChange = (index) => {
      setSelectedObservationIndex(index + 1);
      setDateTimeISO(new Date()); // Resets the date/time picker to the current date/time
      setSugarLevel("beforeMeal"); // Resets the sugar level radio buttons to default
      setSelectedFileName(''); // Clears any selected file name
      setNoteContent(''); // Clears note content
      setUserInputs({}); // Resets all user inputs
    };

    const onSave = async () => {
        // Ensure there's a selected observation
        if (selectedObservationIndex == null) {
          console.error("No observation selected.");
          return;
        }
      
        // Fetch the selected observation object based on the selectedObservationIndex
        const selectedObservation = observationArray[selectedObservationIndex - 1];

        if (selectedObservation.display_name === "Photo") {
          const formData = new FormData();
          formData.append("files", selectedFileName);
          formData.append("file_type", "image");
          try {
              setIsLoading(true);
              const uploadResponse = await dispatch(uploadRecordsAction(formData));
              if (uploadResponse?.payload.status === 'success') {
                  // Assuming uploadResponse includes file details needed for the next dispatch
                  const payload = {
                      patient_id: selectedPatientId,
                      report_info: uploadResponse?.payload?.result?.map(file => ({
                          file_type: file.file_type,
                          mimetype: file.mimetype,
                          file_id: file.file_id,
                          file_name: file.file_name,
                          file_size: file.file_size,
                          s3_file_url: file.s3_file_url,
                          note: noteContent
                      }))
                  };
                  await dispatch(sendRecordsAction({ type: 'image', payload }));
                  await dispatch(
                    getRecordsAction({
                      record_type: selectedObservation?.sub_type,
                      patient_id: selectedPatientId,
                      limit: 5,
                    })
                  )
                  navigate(`/patient-records?patient_id=${selectedPatientId}`);
              } else {
                  console.error("Failed to upload photo", uploadResponse);
              }
          } catch (error) {
              console.error("Error during photo upload", error);
          } finally {
              setIsLoading(false);
          }
        }
      else {
        // Prepare components for payload
        let components = selectedObservation.codes.component.map((component) => {
          let componentValue = userInputs[component.code]; // Get user input for the component
          if (!componentValue) {
            console.warn(`No input for component ${component.code}`);
            return null; // Consider how you want to handle missing inputs
          }
          return {
            code: component.code,
            value: componentValue.toString(), // Ensure the value is a string, adjust based on your API requirements
          };
        }).filter(c => c !== null); // Remove any nulls if you added them for missing inputs
      
        // Ensure dateTimeISO is converted to the required string format
        const formattedDateForSubmission = formatISO(dateTimeISO);
      
        // Construct the payload
        const payload = {
          observation_data: {
            observation: {
              observation_type: selectedObservation.codes.code, // Use the observation type code
              components: components,
              date: formattedDateForSubmission,
            },
          },
          patient_id: selectedPatientId,
        };
      
        // Call the API or dispatch an action with the payload
        try {
          setIsLoading(true);
          const response = await dispatch(addObservationAction(payload));
          if (response?.payload.status === 'success') {
            await dispatch(
              getRecordsAction({
                record_type: selectedObservation?.sub_type,
                patient_id: selectedPatientId,
                limit: 5,
              })
            )
            setTimeout(() => {
              navigate(`/patient-records?patient_id=${selectedPatientId}`);
            }, 1000);
          } else {
            // Handle failure
            console.error("Failed to add observation", response);
          }
        } catch (error) {
          console.error("Error adding observation", error);
        } finally {
          setIsLoading(false);
        }
      }
    };       

    return(
        <ThemeProvider theme={theme}>
            <Container>
                <TopHeader>
                    <ArrowBackImage src={IBackArrow} alt="back-arrow" 
                    onClick={() => {
                        navigate(-1);
                    }} 
                    />
                    Add Data
                </TopHeader>
                <ObservationContainer>
                    <ObservationSelector>
                        {observationArray.map((observation, index) => (
                            <ObservationTile
                                key={index + 1}
                                isSelected={selectedObservationIndex === index + 1}
                                onClick={() => handleObservationChange(index)}>
                                <ObservationDetail>
                                    <ObservationThumb src={observation?.icon}></ObservationThumb>
                                    <ObservationName>{observation?.display_name}</ObservationName>
                                </ObservationDetail>
                                <NextArrowIcon src={INextArrow}></NextArrowIcon>
                            </ObservationTile>
                        ))}
                    </ObservationSelector>
                    <ObservationEntry>
                        <ObservationInfo>
                            <ObservationLogoSmall src={selectedObservation?.icon}></ObservationLogoSmall>
                            <ObservationNameSmall>{selectedObservation?.display_name}</ObservationNameSmall>
                        </ObservationInfo>
                        {selectedObservation?.display_name === "Questionnaire" && !showQuestionnaireForm && (
                            <ObservationQuestionnaireType
                                onSelectFormType={handleSelectFormType}
                            />
                        )}

                        {showQuestionnaireForm && selectedFormType && (
                            <ObservationQuestionnaireForm 
                                formType={selectedFormType} 
                                onBack={handleBackToTypeSelection}
                            />
                        )}
                        {selectedObservation?.display_name === "Sugar Level" && (
                            <RadioGroup
                            value={sugarLevel}
                            onChange={(event) => setSugarLevel(event.target.value)}
                            >
                                <FormControlLabel value="beforeMeal" control={<Radio />} label="Before meal sugar" />
                                <FormControlLabel value="afterMeal" control={<Radio />} label="After meal sugar" />
                                <TextField
                                    label="Sugar Level"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    value={userInputs[getComponentCodeForSugarLevel(sugarLevel)] || ''} // Dynamically access the input value
                                    onChange={(e) => handleInputChange(e, getComponentCodeForSugarLevel(sugarLevel))}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">mg/dL</InputAdornment>
                                    }}
                                />
                            </RadioGroup>
                        )}
                        {selectedObservation?.display_name === "Blood Pressure" && (
                            <>
                                <TextField
                                    label="Systolic"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    InputProps={{ endAdornment: <InputAdornment position="end">mmHg</InputAdornment>, }}
                                    value={userInputs[findComponentCode("Systolic")] || ''}
                                    onChange={(e) => handleInputChange(e, findComponentCode("Systolic"))}
                                />
                                <TextField
                                    label="Diastolic"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    InputProps={{ endAdornment: <InputAdornment position="end">mmHg</InputAdornment>, }}
                                    value={userInputs[findComponentCode("Diastolic")] || ''}
                                    onChange={(e) => handleInputChange(e, findComponentCode("Diastolic"))}
                                />
                                <TextField
                                    label="Pulse"
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    InputProps={{ endAdornment: <InputAdornment position="end">BPM</InputAdornment>, }}
                                    value={userInputs[findComponentCode("Pulse")] || ''}
                                    onChange={(e) => handleInputChange(e, findComponentCode("Pulse"))}
                                />
                            </>
                        )}
                        {selectedObservation?.display_name === "Photo" && (
                            <>
                                <Button
                                variant="contained"
                                component="label"
                                sx={{ backgroundColor: '#0099CC', "&:hover": { backgroundColor: '#007B9E' }, marginBottom: '20px' }}
                                >
                                Upload Photo
                                <input    
                                    type="file"
                                    hidden
                                    accept="image/*"
                                    onChange={handleFileChange}
                                />
                                </Button>
                                {selectedFileName && <Typography sx={{ ml: 2, display: 'inline' }}>{selectedFileName.name}</Typography>}
                                <TextField 
                                    label="Add note" 
                                    variant="outlined" 
                                    margin="normal" 
                                    fullWidth 
                                    value={noteContent}
                                    onChange={(e) => setNoteContent(e.target.value)} 
                                />
                            </>
                        )}
                        {selectedObservation?.display_name === "Pain Level" && (
                            <>
                                <Typography 
                                gutterBottom
                                sx={{
                                    color: '#808080',
                                    fontSize: '13px',
                                }}
                                >
                                Add Pain Level
                                </Typography>
                                <Slider
                                    defaultValue={0}
                                    step={1}
                                    min={0}
                                    max={10}
                                    valueLabelDisplay="auto"
                                    value={userInputs[findComponentCode("Pain Level")] || 0}
                                    onChange={(e, newValue) => setUserInputs({ ...userInputs, [findComponentCode("Pain Level")]: newValue })}
                                    marks={[
                                        {
                                        value: 0,
                                        label: '0',
                                        },
                                        {
                                        value: 10,
                                        label: '10',
                                        },
                                    ]}
                                    sx={{
                                        maxWidth: '90%',
                                        mx: 'auto',
                                        margin: '20px',
                                        }}
                                />
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ color: '#7ABA56', fontSize: '13px' }}>No pain</Typography>
                                <Typography sx={{ color: '#D2042D', fontSize: '13px' }}>Extreme pain</Typography>
                                </Box>
                            </>
                            )}
                        {selectedObservation?.display_name === "Weight" && (
                            <TextField
                                label="Add weight"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={userInputs[findComponentCode("Weight")] || ''}
                                onChange={(e) => setUserInputs({ ...userInputs, [findComponentCode("Weight")]: e.target.value })}
                                InputProps={{ endAdornment: <InputAdornment position="end">KGS</InputAdornment> }}
                            />
                        )}
                        {selectedObservation?.display_name === "Temperature" && (
                            <TextField
                                label="Add temperature"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                value={userInputs[findComponentCode("Temperature")] || ''}
                                onChange={(e) => setUserInputs({ ...userInputs, [findComponentCode("Temperature")]: e.target.value })}
                                InputProps={{ endAdornment: <InputAdornment position="end">°Fahrenheit</InputAdornment> }}
                            />
                        )}
                        {selectedObservation?.display_name === "Notes" && (
                            <TextField
                                label="Add notes"
                                variant="outlined"
                                margin="normal"
                                fullWidth
                                multiline
                                rows={4}
                                value={userInputs[findComponentCode("Notes")] || ''}
                                onChange={(e) => setUserInputs({ ...userInputs, [findComponentCode("Notes")]: e.target.value })}
                            />
                        )}
                        {selectedObservation?.display_name !== "Questionnaire" && (
                        <DateContainer>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DateTimePicker
                              label="Date & Time"
                              value={dateTimeISO}
                              onChange={setDateTimeISO}
                              maxDateTime={new Date()}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  margin="normal"
                                  fullWidth
                                  InputProps={{
                                    ...params.InputProps,
                                    readOnly: true,
                                  }}
                                  sx={{
                                    mt: 4,
                                    '& .MuiInputBase-input': {
                                      color: '#808080',
                                      fontSize: '16px',
                                    },
                                    '& .MuiOutlinedInput-root': {
                                      '& fieldset': {
                                        borderColor: '#D7EFF7',
                                      },
                                    },
                                  }}
                                />
                              )}
                            />
                            </LocalizationProvider>
                            <SaveButton onClick={onSave} type="submit">Save</SaveButton>
                        </DateContainer>
                        )}
                    </ObservationEntry>

                </ObservationContainer>
                <SnackBar />
            </Container>
        </ThemeProvider>
    );
}

export default AddObservation;