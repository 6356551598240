import styled from "styled-components";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../../utilities/constants"; 

export const Container = styled.div`
  margin: 40px;
  padding: 24px;
  border-radius: 12px;
  background-color: #FFFFFF;
  box-shadow: 0px 0px 32px #2477b314;
  opacity: 1;
  margin-bottom: 0;
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  padding: 20px;
  align-items: center;
  justify-content: space-between;
`;
export const Header = styled.div`
  font-size: 30px;
  color: #0099cc;
`;
export const HeaderLeft = styled.div`
  display: flex;
  flex: 0.4;
`;
export const ProfileHeader = styled.div`
  height: 96px;
  background-color: #EBF7FB;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
`;

export const ProfileLabel = styled.div`
  font-size: 16px;
  color: #808080;
  margin-bottom: 5px;
`;

export const ProfileData = styled.div`
  font-size: 16px;
  color: #000000;
`;
export const ListWrapper = styled.div``;
export const ListSection = styled.div`
  height: 50px;
  background-color: #ebf7fb;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  cursor: pointer;
`;
export const ListDateTitle = styled.div`
  display: flex;
  flex: 0.4;
  font-weight: bold;
  color: #0099cc;
  font-size: 16px;
`;
export const ListItemWrapper = styled.div`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 30px;
  padding-bottom: 45px;
  padding-left: 20px;
`;

export const ListItem = styled.div`
  font-size: 16px;
  color: #000000;
`;
export const ArrowImage = styled.img``;
