import styled from "styled-components";
import { Typography, Button } from "@mui/material";
import MinimizeIcon from '@mui/icons-material/Minimize';

export const ModalHeader = styled(Typography)`
  color: #0099CC;
  font-size: 24px !important;
  margin: 28px 49px 0 49px !important;
  text-align:center;
  background-color:#ebf7fa;
  margin: 0 !important;
  padding: 15px 0;
`;

export const CustomRecordBox = styled.div`
display: flex;
justify-content: space-between;
width:100%;
background-color:#cb8552;
height:60px;
align-items:center;
width: calc(100% - 20px);
padding:0 10px;
border-radius: 10px;
margin-bottom:10px;
.text-speech-time{
  display:flex;
  gap:15px;
  align-items:center;
}
.grouth-icon{
  width: 250px;
  height: 35px;
  @media(max-width:991px){
    width:190px;
  }
  @media(max-width:500px){
    width:50px;
  }
  img{
    width: 100%;
    height: 35px;
    object-fit: cover;
    mix-blend-mode: darken;
  }
}
.text-speech-time{
  color:#fff;
}
.record-btn{
  background: none;
  border: none;
  color: #fff;
}
.record-button{
  display: flex;
  gap:10px;
  button{
    cursor: pointer;
    border-radius: 50%;
    height: 25px;
    width: 25px;
    border: none;
    line-height: 28px;
    img{
      padding:2px;
    width: 10px;

    }
  }
}
`

export const ModalBody = styled.div`
  // margin: 25px;
  padding:20px;
  margin-bottom: 0;
  position: relative;
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .audio-recorder-container{
    justify-content: center;
    display: flex;
    gap:10px;
    align-items: center;
        span.devider {
          position: relative;
          color:#808080
        }
      span.devider:after {
        width: 1px;
        height: 36px;
        top: calc(100% + 5px);
        background: #ddeaed;
        content: "";
        position: absolute;
        left: 6px;
        }
        span.devider:before {
          width: 1px;
          height: 36px;
          top: calc(-100% - 20px);
          background:#ddeaed;
          content: "";
          position: absolute;
          left: 6px;
          }
    .audio-recorder-custom{
      min-width: 160px;
      flex-wrap: wrap;
      display: flex;
      justify-content: center;
      .recording-buitton{
        background-color: #cb8552;
          width: 60px;
          height: 60px;
          justify-content: center;
          position: relative;
          border-radius: 50%;
          display: flex;
          img{
            width:30px;
          }
      }
        .record-text{
          display: block;
          width: 100%;
          font-size:14px;
          text-align: center;
        }
  }
}
  .recording-screen{
    .recorder-box{
      opacity: 0;
      height:0;

    }
    width:100%;
    .audio-recorder-timer {
      color:#fff;
    }
    img{
    // filter: invert(1);
    }
    .audio-recorder.recording {
      width:100%;
      background: #cb8652;
      position: relative;
      padding: 10px 25px;
      margin-bottom: 25px;
      &:before {
        content: "Record";
        position: absolute;
        color: #fff;
        left: 15px;
      }
      .audio-recorder-mic {
        opacity:0;
      }

    }
    .audio-recorder-options{
      position:relative;
    }
      .audio-recorder-options:after {
      width: 100%;
      content: "";
      position: absolute;
      background: red;
      top: 0;
      right: 0;
      left: 0;
      z-index: 9999;
      height: 100%;
    }
  }
`;
export const Transcribe = styled.button`
background: #2477b3;
box-shadow: none;
border: none;
color: #fff;
padding: 10px 30px;
border-radius: 10px;
`
export const PreviewAudioSection = styled.div`
    width:100%;
    audio{
      width:100%;
    }
    audio::-webkit-media-controls-play-button,
     audio::-webkit-media-controls-panel {
     background-color: #30aed7;
     box-shadow: none;
     }
     audio::-webkit-media-controls-enclosure, video::-webkit-media-controls-enclosure{
      border-radius: 15px;
      border:none;
     }
     audio::-webkit-media-controls-current-time-display, video::-webkit-media-controls-current-time-display,
      audio::-webkit-media-controls-time-remaining-display, video::-webkit-media-controls-time-remaining-display{
        color:#fff;
      }
      .pre-auto-contant{
        display:flex;
        justify-content: space-between;
        background: #30aed7;
        color:#fff;
        padding: 5px 10px;
        align-items: center;
        border-radius: 10px;
        margin-bottom:20px;
      }
  
`
export const SideIcon = styled.img`
`;
export const TranscriptionextArea = styled.textarea`
  width: 100%;
  box-sizing: border-box;
  border-radius: 12px;
  border: 2px solid #cb8552;
  padding: 10px;
  min-height: 240px;
  resize: none;
  overflow-y: auto;
  margin-bottom: 15px;
`;

export const ModalBodySecond = styled.div`
  margin: 25px;
`;
export const CloseButton = styled.img`
  cursor: pointer;
  display: block;
  max-width: 100%;
`;
export const StyledMinimizeIcon = styled(MinimizeIcon)`
  cursor: pointer;
`;
export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1000;
`;
export const BackButton = styled(Button)`
  cursor: pointer;
  display: block;
  max-width: 100%;
  color: #0099CC !important;
`;
export const BackButtonWrapper = styled.div`
  border: 1px solid #0099CC;
  border-radius: 8px;
  max-width: 7%;
`;
export const AddToEhrButton = styled.button`
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 11px 56px;
  margin: 15px 0px;
  align-self: center;`
export const SaveButton = styled.button`
  background-color: #7aba56;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 11px 56px;
  margin: 18px 25px;
  margin-top: 20px;
  align-self: center;

  &:disabled {
    background-color: gray;
    cursor: not-allowed;
  }
`;
export const CopySummaryButton = styled.button`
  background-color: #0099CC;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 11px 28px;
  margin-left: 10px;
`;

export const FinishButton = styled.button`
  background-color: #7aba56;
  border-radius: 8px;
  border: none;
  color: white;
  font-size: 14px;
  cursor: pointer;
  padding: 11px 28px;
`;

export const LabelTextArea = styled.div`
    display: flex;
    align-items: center;
`;

export const TextAreaWrapper = styled.div`
    margin: 10px;
`;

export const Label = styled.label`
    font-size: 14px;
    color: #808080;
`;

export const TextArea = styled.textarea`
    width: 100%;
    box-sizing: border-box;
    border-radius: 12px;
    border: 1px solid #92D4E9;
    padding: 5px 10px;
    height: 88px;
    resize: none;
    overflow-y: auto;
    margin-top: 10px;
`;

export const LoaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`;

export const StyledSelect = styled.select`
    padding: 11px;
    border-radius: 8px;
    border: 1px solid #92D4E9;
`;

export const StartTranscriptionContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const FromText = styled.span`
  margin-right: 10px;
`;

export const EHRContainer = styled.div`
    margin-top: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
`;