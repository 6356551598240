import React, { useRef, useState, useEffect } from "react";
import { Box, Modal } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
    IAddIconLarge,
    ICloseModal,
    IFileIcon,
  } from "../../../assets";
import { uploadRecordsAction, sendRecordsAction, sendScansAction } from "../../../store/actions";
import { patientSelector } from "../../../store/slice/patient.slice";
import {
    HeaderWrapper,
    SectionWrapper,
    Header,
    AddButton,
    ModalBody,
    Section,
    UploadBoxWrapper,
    DragText,
    PdfText,
    FileName,
    SectionTitle,
    UploadBox,
    InnerUploadContainer,
    FileUploadBox,
    CrossIcon,
    UploadButton,
    CloseButton,
    CloseButtonWrapper
  } from "./style.components";
import { Loader } from "../../shared";
import {UppyUploader} from "../../layout";

const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: '90%',
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: '12px',
};

const AddRecords = ({ open, handleClose, handleSave, pdfFiles }) => {
    const fileInputRef = useRef(null);
    const { selectedPatientId } = useSelector(patientSelector);
    const [isUploadDisabled, setIsUploadDisabled] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [medicalLetterArray, setMedicalLetterArray] = useState([]);
    const [prescriptionArray, setPrescriptionArray] = useState([]);
    const [reportsArray, setReportsArray] = useState([]);
    const [imagingArray, setImagingArray] = useState([]);
    const currentRecordType = useRef(null);
    const dispatch = useDispatch();
    const [uppyResponse, setUppyResponse] = useState("");

    useEffect(() => {
        if (open) {
            setReportsArray([]);
            setImagingArray([]);
            setPrescriptionArray([]);
            setMedicalLetterArray([]);
            setUppyResponse(null);
        }
    }, [open]);
    
    useEffect(() => {
        if (pdfFiles) {
            if (pdfFiles.summary) {
                uploadFile(pdfFiles.summary, "medical_letter");
            }
            if (pdfFiles.transcription) {
                uploadFile(pdfFiles.transcription, "medical_letter");
            }
            if (pdfFiles.prescription) {
                uploadFile(pdfFiles.prescription, "prescription");
            }
        }
    }, [pdfFiles]);

    const transformAndCombineArrays = (medicalLetters, prescriptions, reports, imaging) => {
        const transform = array => array.map(item => ({
            file_id: item.file_id,
            file_name: item.file_name,
            file_size: item.file_size,
            s3_file_url: item.s3_file_url,
            document_type: item.file_type,
            content_type: item.mimetype
        }));

        return [
            ...transform(medicalLetters),
            ...transform(prescriptions),
            ...transform(reports),
            ...transform(imaging)
        ];
    };
    
    useEffect(() => {
        setIsUploadDisabled(!(medicalLetterArray.length || prescriptionArray.length || reportsArray.length || imagingArray.length || uppyResponse));
    }, [medicalLetterArray, prescriptionArray, reportsArray, imagingArray, uppyResponse]);

    
    const handleDragOver = (event) => {
        event.preventDefault();
    };
    
    const uploadFile = async (file, fileType) => {
        setIsLoading(true);
        var formData = new FormData();
        formData.append("files", file);
        formData.append("file_type", fileType);
        try {
            const uploadResponse = await dispatch(uploadRecordsAction(formData));
            const response = uploadResponse?.payload;
            setIsLoading(false);
            if (response?.status === "success") {
                const transformedResponse = response.result.map(item => ({
                    ...item,
                    document_type: item.file_type,
                    content_type: item.mimetype,
                    file_id: item.file_id,
                    file_name: item.file_name,
                    file_size: item.file_size,
                    s3_file_url: item.s3_file_url
                }));
    
                switch (fileType) {
                    case "medical_letter":
                        setMedicalLetterArray(prev => [...prev, ...transformedResponse]);
                        break;
                    case "prescription":
                        setPrescriptionArray(prev => [...prev, ...transformedResponse]);
                        break;
                    case "imaging":
                        setImagingArray(prev => [...prev, ...transformedResponse]);
                        break;
                    case "report":
                        setReportsArray(prev => [...prev, ...transformedResponse]);
                        break;
                    default:
                }
            } else {
                alert("Something went wrong!");
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error calling upload records:", error);
        }
    };
        
    
    const openFilePicker = (recordType) => {
        currentRecordType.current = recordType;
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    
    const handleFileSelect = (event) => {
        const files = event.target.files;
        if (files && files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                uploadFile(files[i], currentRecordType.current);
            }
        }
        event.target.value = null;
    };
    
    const handleDrop = (event) => {
        event.preventDefault();
        if (event.dataTransfer.items) {
            for (let i = 0; i < event.dataTransfer.items.length; i++) {
                const fileItem = event.dataTransfer.items[i];
                if (fileItem.kind === 'file') {
                    const file = fileItem.getAsFile();
                    if (file) {
                        uploadFile(file);
                    }
                }
            }
        }
    };

    const handleSend = async () => {
        if (isUploadDisabled) return;
    
        const combinedArray = transformAndCombineArrays(
            medicalLetterArray, 
            prescriptionArray, 
            reportsArray, 
            imagingArray
        );
    
        const promises = [];
        if (combinedArray.length > 0) {
            const payload = {
                "patient_id": selectedPatientId,
                "reports": combinedArray,
            };
            promises.push(dispatch(sendRecordsAction(payload)));
        }
    
        if (uppyResponse) {
            const payload_scans = {
                "patient_id": selectedPatientId,
                "folder_id": uppyResponse,
            };
            promises.push(dispatch(sendScansAction(payload_scans)));
        }
    
        if (promises.length === 0) {
            console.error("No data to send");
            return;
        }
    
        try {
            await Promise.all(promises);
            handleClose();
            if (handleSave) {
                handleSave();
            }
        } catch (error) {
            console.error("Error sending records or scans:", error);
        }
    };    

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <input 
                    type="file" 
                    style={{ display: 'none' }} 
                    ref={fileInputRef}
                    onChange={handleFileSelect}
                    multiple
                />
                <HeaderWrapper>
                    <Header>Add Records</Header>
                    <AddButton 
                        onClick={handleSend} 
                        disabled={isUploadDisabled}
                    >
                        Send
                    </AddButton>
                    <CloseButton src={ICloseModal} onClick={handleClose} />
                </HeaderWrapper>
                <ModalBody>
                    <Section>
                        <SectionTitle>Letters and Summaries</SectionTitle>
                        <UploadBoxWrapper>
                            <UploadBox onClick={() => openFilePicker('medical_letter')}>
                                <img src={IAddIconLarge} alt="Add Icon" />
                                <DragText>Select files to add</DragText>
                            </UploadBox>
                            {isLoading ? (
                                <Loader />
                            ) : (
                            medicalLetterArray && medicalLetterArray.length > 0 ? (
                                medicalLetterArray?.map(row => (
                                    <FileUploadBox key={row?.file_id}>
                                        <CrossIcon                                           
                                            onClick={() => {
                                                setMedicalLetterArray(prevArray => prevArray.filter(item => item.file_id !== row.file_id));
                                            }}
                                        >X</CrossIcon>
                                        <InnerUploadContainer>
                                            <img src={IFileIcon}></img>
                                            <PdfText>PDF</PdfText>
                                        </InnerUploadContainer>
                                        <FileName>{row.file_name}</FileName>
                                    </FileUploadBox>
                                ))
                            ) : null )}
                        </UploadBoxWrapper>
                    </Section>
                    <Section>
                        <SectionTitle>Prescription</SectionTitle>
                        <UploadBoxWrapper>
                            <UploadBox onClick={() => openFilePicker('prescription')}>
                                <img src={IAddIconLarge} alt="Add Icon" />
                                <DragText>Select files to add</DragText>
                            </UploadBox>
                            {isLoading ? (
                                <Loader />

                            ) : (
                            prescriptionArray && prescriptionArray.length > 0 ? (
                                prescriptionArray?.map(row => (
                                    <FileUploadBox key={row?.file_id}>
                                        <CrossIcon                                           
                                            onClick={() => {
                                                setPrescriptionArray(prevArray => prevArray.filter(item => item.file_id !== row.file_id));
                                            }}
                                        >X</CrossIcon>
                                        <InnerUploadContainer>
                                            <img src={IFileIcon}></img>
                                            <PdfText>PDF</PdfText>
                                        </InnerUploadContainer>
                                        <FileName>{row.file_name}</FileName>
                                    </FileUploadBox>
                                ))
                            ) : null )}
                        </UploadBoxWrapper>
                    </Section>
                    <Section>
                        <SectionTitle>Reports</SectionTitle>
                        <UploadBoxWrapper>
                            <UploadBox onClick={() => openFilePicker('report')} onDrop={handleDrop} onDragOver={handleDragOver} draggable>
                                <img src={IAddIconLarge} alt="Add Icon" />
                                <DragText>Select files to add</DragText>
                            </UploadBox>
                            {isLoading ? (
                                 <Loader />

                            ) : (
                            reportsArray && reportsArray.length > 0 ? (
                                reportsArray?.map(row => (
                                    <FileUploadBox key={row?.file_id}>
                                        <CrossIcon                                           
                                            onClick={() => {
                                                setReportsArray(prevArray => prevArray.filter(item => item.file_id !== row.file_id));
                                            }}
                                        >X</CrossIcon>
                                        <InnerUploadContainer>
                                            <img src={IFileIcon}></img>
                                            <PdfText>PDF</PdfText>
                                        </InnerUploadContainer>
                                        <FileName>{row.file_name}</FileName>
                                    </FileUploadBox>
                                ))
                            ) : null )}
                        </UploadBoxWrapper>
                    </Section>
                    <Section>
                        <SectionTitle>Imaging</SectionTitle>
                        <UploadBoxWrapper>
                            <UploadBox onClick={() => openFilePicker('imaging')} onDrop={handleDrop} onDragOver={handleDragOver} draggable>
                                <img src={IAddIconLarge} alt="Add Icon" />
                                <DragText>Select files to add</DragText>
                            </UploadBox>
                            {isLoading ? (
                                <Loader />

                            ) : (
                            imagingArray && imagingArray.length > 0 ? (
                                imagingArray?.map(row => (
                                    <FileUploadBox key={row?.file_id}>
                                        <CrossIcon                                           
                                            onClick={() => {
                                                setImagingArray(prevArray => prevArray.filter(item => item.file_id !== row.file_id));
                                            }}
                                        >X</CrossIcon>
                                        <InnerUploadContainer>
                                            <img src={IFileIcon}></img>
                                            <PdfText>PDF</PdfText>
                                        </InnerUploadContainer>
                                        <FileName>{row.file_name}</FileName>
                                    </FileUploadBox>
                                ))
                            ) : null )}
                        </UploadBoxWrapper>
                    </Section>
                    <Section>
                        <SectionTitle>Scans</SectionTitle>
                        <UppyUploader onUploadComplete={setUppyResponse} />
                    </Section>
                </ModalBody>
            </Box>
        </Modal>

    );
}

export default AddRecords;
