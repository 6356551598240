import { Viewer, Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import hexToRgba from "hex-to-rgba";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { GlobalWorkerOptions } from "pdfjs-dist";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ShimmerContentBlock } from "react-shimmer-effects";
import { getChartRecords } from "../../../api/records.api";
import { SnackBar } from "../../../components/shared";
import { Button, Menu, MenuItem, Grid } from "@mui/material";
import {
  getRecordTypeAction,
  getRecordsAction,
} from "../../../store/actions";
import {
  getReportURL,
  getThumbnails,
} from "../../../store/actions/records.action";
import { resetThumbnail } from "../../../store/slice/thumbanils.slice"; 
import { recordTypeSelector } from "../../../store/slice/record-type.slice";
import GraphicalRecord from "./graphical-record";
import {
  AddButton,
  CardDetailsWrapper,
  CardsContainer,
  Container,
  EmptyListMessage,
  Header,
  HeaderWrapper,
  ListDateTitle,
  ListItemDate,
  ListItemWrapper,
  ListWrapper,
  LoaderWrapper,
  StyledCloseButton,
  StyledImage,
  StyledModal,
  StyledModalContent,
  ListItemTop,
  AddIcon,
  AddText,
  AddButtonQuestionnaire,
  ListSectionQuestionnaire,
  ListDateTitlQuestionnaire,
  SeeMoreWrapper,
  SeeMoreText,
  NextArrow,
} from "./style.components";
import { IAddIconWhite, INextArrow } from "../../../assets";
import Breadcrumb from "../../../components/layout/Breadcrumb";
import RecordItem from "../../../components/layout/RecordItem/RecordItem";
import ChangeStatusDialog from "../../../components/shared/ChangeStatusDialog/ChangeStatusDialog";
import { changeStatusRestrictedToAvailableAction } from "../../../store/actions/records.action";
import { toast } from "react-toastify";
import { resetState } from "../../../store/slice/records.slice";

const workerUrl = `//${window.location.host}/pdf.worker.min.js`;
GlobalWorkerOptions.workerSrc = workerUrl;



const OBSERVATION_RECORDS = [
  "blood_pressure",
  "blood_sugar",
  "pain_level",
  "temperature",
  "weight",
];

const PatientRecords = () => {
  const dispatch = useDispatch();
  const selectedPatientId = useSelector(
    (state) => state?.patient?.selectedPatientId
  );
  const [open, setOpen] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [recordTypesArray, setRecordTypesArray] = useState([]);
  const recordTypes = useSelector((state) => recordTypeSelector(state));
  const [isLoading, setIsLoading] = useState(false);
  const [observationRecordTypes, setObservationRecordTypes] = useState([]);
  const [observationData, setObservationData] = useState({});
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const openDropdown = Boolean(anchorEl);
  const [itemsToShow, setItemsToShow] = useState(
    getItemsToShow(window.innerWidth)
  );
  const screenWidth = window.innerWidth;
  const isMobileWidth = screenWidth <= 768;
  const [overallLoading, setOverallLoading] = useState(true);
  const [storeData, setStoreData] = useState(null);
  const [isShowhangeStatus, setShowChangeStatus] = useState(false);
  const [issStatusChangeLoading, setIsStatusChangeLoading] = useState(false);
  const [isloadingThumbnail, setIsLoadingThumbnail] = useState(false);
  const accordionData = useSelector((state) => state?.record?.records);
  const thumbnails = useSelector((state) => state?.thumbnail?.thumbnails);
  const [tempAccordionData, setTempAccrodionData] = useState({});

  function getItemsToShow(width) {
    if (width >= 1050) {
      return 5;
    } else if (width >= 1005) {
      return 3;
    } else if (width >= 850) {
      return 2;
    } else if (width >= 767) {
      return 1;
    } else if (width >= 650) {
      return 3;
    } else if (width >= 506) {
      return 2;
    } else {
      return 1;
    }
  }

  const breadCrubLinks = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Health Data",
    },
  ];

  useEffect(() => {
    function handleResize() {
      const newItemsToShow = getItemsToShow(window.innerWidth);
      if (newItemsToShow !== itemsToShow) {
        setItemsToShow(newItemsToShow);
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    handleClose();
  };

  const fetchReportUrl = async (filePath) => {
    try {
      const payload = {
        file_path: filePath,
      };
      const response = await dispatch(getReportURL({ payload: payload }));
      if (response?.payload?.status === "success") {
        return response?.payload?.data;
      } else {
        return "";
      }
    } catch (error) {
      console.log("error occured while fetching url");
      return "";
    }
  };

  useEffect(() => {
    dispatch(getRecordTypeAction(selectedPatientId));
  }, [selectedPatientId, dispatch]);

  useEffect(() => {
    const fetchAllRecords = async (isPageRefresh = false) => {
      const fetchPromises = recordTypesArray.map(({ record_type }) => {
        if(record_type !== "chart")
          fetchRecords(record_type ,false, isPageRefresh)
      }
      );

      try {
        await Promise.all(fetchPromises);
        setIsLoading(!isLoading);
      } catch (error) {
        console.error("Error fetching one or more records:", error);
      }
    };
    if(accordionData && Object.keys(accordionData)?.length && (selectedPatientId !== accordionData.patient_id)) {
      dispatch(resetState());
      dispatch(resetThumbnail());
      fetchAllRecords(false);
    }else if(accordionData && Object.keys(accordionData)?.length && (selectedPatientId === accordionData.patient_id)){
      fetchAllRecords(true)
    }else {
      fetchAllRecords(false)
    }
  }, [recordTypesArray, selectedPatientId, dispatch]);

  useEffect(() => {
    if (recordTypes && recordTypes?.length) {
      const excludeRecordTypes = [
        "blood_pressure",
        "blood_sugar",
        "pain_level",
        "temperature",
        "weight",
      ];

      const updatedRecordTypes = recordTypes
        .filter((record) => !excludeRecordTypes.includes(record.record_type))
        .map((record) => {
          return record;
        });
      const updatedObsRecordTypes = recordTypes
        .filter((record) => OBSERVATION_RECORDS.includes(record.record_type))
        .map((record) => {
          return record;
        });
      setRecordTypesArray(updatedRecordTypes);
      setObservationRecordTypes(updatedObsRecordTypes);
    }
  }, [recordTypes]);

  const initialState = recordTypesArray?.reduce((acc, { record_type }) => {
    acc[record_type] = "loading";
    return acc;
  }, {});
  const [loadedAccordions, setLoadedAccordions] = useState(initialState);

  const extractIdAndFilePath = () => {
    const result = [];
    Object.keys(accordionData)?.length &&
      Object.keys(accordionData)?.forEach((category) => {
        if (category === "scans" || category === "patient_id" || category === "questionnaire") return;
        accordionData[category]?.data.length &&
          accordionData[category].data.forEach((item) => {
            if (item.id && item.file_path) {
              result.push({
                id: item.id,
                file_path: item.file_path,
                record_type: category,
              });
            }
          });
      });
    return result;
  };

  const fetchThumbnailImages = async () => {
    const data = extractIdAndFilePath();
    const filePaths = data
      .filter((item) => item?.file_path && !(thumbnails && thumbnails[item.id]))
      .map((item) => ({
        file_path: item.file_path,
        fhir_id: item.id,
      }));
    filePaths.length &&
      dispatch(
        getThumbnails({
          file_objects: filePaths,
        })
      ).then(() => {
        setIsLoadingThumbnail(!isloadingThumbnail);
      });
  };

  useEffect(() => {
    if (tempAccordionData && (Object.keys(tempAccordionData).length === recordTypesArray.length)) {
      fetchThumbnailImages();
    }
  }, [tempAccordionData]);

  useEffect(() => {
    const isAnyLoading = Object.values(loadedAccordions).some(
      (status) => status === "loading"
    );
    setOverallLoading(isAnyLoading);
  }, [loadedAccordions]);

  const fetchRecords = (recordType, isLoad = false, isPageRefresh=false) => {
    return new Promise(async (resolve, reject) => {
      if (loadedAccordions[recordType] && !isLoad) return resolve();
      !isPageRefresh && setLoadedAccordions((prev) => ({ ...prev, [recordType]: "loading" }));
      if (selectedPatientId) {
        try {
          const action = await dispatch(
            getRecordsAction({
              record_type: recordType,
              patient_id: selectedPatientId,
              limit: 5,
            })
          );
          if(action?.payload?.status === "success") {
            setTempAccrodionData((prev) => ({ ...prev, [recordType]: action?.payload?.data }));
            setLoadedAccordions((prev) => ({ ...prev, [recordType]: "loaded" }));
          }
          resolve();
        } catch (error) {
          console.error("Error fetching records:", error);
          setLoadedAccordions((prev) => ({ ...prev, [recordType]: "error" }));
          reject(error);
        }
      } else {
        resolve();
      }
    });
  };

  const isAccordionDataEmpty =
    accordionData &&
   Object
      .entries(accordionData)
      .filter(([key]) => key !== "patient_id")
      .every(([key, value]) => value?.total === 0);

  const showEmptyMessage =
    isAccordionDataEmpty && accordionData && accordionData["questionnaire"]?.total === 0;

  const handleClickDropdownMenu = () => {
    navigate("share", {
      state: {
        data: storeData,
      },
    });
  };

  const handleListItemClick = async (record) => {
    if (record?.record_type === "scans") {
      const url = isMobileWidth
        ? record?.mobile_viewer_url
        : record?.dicom_viewer_url;
      const newWindow = window.open("", "_blank");
      if (newWindow) newWindow.location.href = url;
    } else if (record?.record_type === "questionnaire") {
      navigate("questionnaire-type/questionnaire-form", {
        state: { filled_form_id: record.questionnaire_response_ids[0].id },
      });
    } else if (record?.name === "Notes") {
      return;
    } else if (
      record?.record_type !== "questionnaire" ||
      record?.record_type !== "scans" ||
      record?.record_type !== "notes"
    ) {
      const url = await fetchReportUrl(record?.file_path);
      url && setSelectedRecord({ ...record, url: url });
      setOpen(true);
    }
  };

  const closeModal = () => {};

  const getObservationRecords = useCallback(
    async (r) => {
      const response = await getChartRecords(r, selectedPatientId);
      const result = response?.result;
      let dataset = [];
      result?.datasets?.forEach((item) => {
        let data = [];
        item?.data?.forEach((values) => {
          data.push({
            y: values?.value,
            x: moment(values.timestamp).format("YYYY-MM-DD HH:mm"),
          });
        });
        dataset.push({
          label: item.label,
          borderColor: item.color,
          data,
          backgroundColor: hexToRgba(item.color, "0.5"),
        });
      });
      observationData[r] = dataset;
      setObservationData(observationData);
    },
    [observationData, selectedPatientId]
  );

  useEffect(() => {
    if (observationRecordTypes?.length > 0) {
      observationRecordTypes.forEach(({ record_type }) => {
        getObservationRecords(record_type);
      });
    }
  }, [observationRecordTypes, getObservationRecords]);

  const onStatusAvailable = async (status) => {
    if (status === "available") {
      setIsStatusChangeLoading(true);
      try {
        const payload = {
          record_type: storeData?.record_type,
          id: storeData?.id,
          status: "AVAILABLE",
        };
        const response = await dispatch(
          changeStatusRestrictedToAvailableAction({ payload: payload })
        );
        if (response && response?.payload?.status === "success") {
          storeData?.record_type === "scan" ? await fetchRecords("scans", true) : await fetchRecords(storeData?.record_type, true);
          toast.success(
            response?.payload?.message,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            },
            500
          );
        } else {
          alert("Something went wrong!");
        }
        setShowChangeStatus(false);
        setIsStatusChangeLoading(false);
      } catch (err) {
        console.log(err);
        setShowChangeStatus(false);
        setIsStatusChangeLoading(false);
        alert("Something went wrong!");
      }
    } else {
      setShowChangeStatus(false);
      setIsStatusChangeLoading(false);
    }
  };

  const onChangeStatus = () => {
    setShowChangeStatus(true);
  };
  const menuItems = [
    { label: "Share", onClick: () => handleClickDropdownMenu() },
  ];
  const menuItemsWithStatusChange = [
    { label: "Share", onClick: () => handleClickDropdownMenu() },
    { label: "Change Status", onClick: () => onChangeStatus() },
  ];
  const handleScanClick = (fhirId) => {
    navigate("scan-info", {
      state: {
        studyFhirId: fhirId,
      },
    });
  };

  const renderListSection = (recordType, name, data) => {
    return (
      <ListDateTitle key={`${recordType}-${name}`}>
        {name} {accordionData[recordType]?.total ? `(${accordionData[recordType].total})` : ""}
      </ListDateTitle>
    );
  };

  const renderGraphList = (recordType, name, data) => {
    return (
      <div
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
        key={recordType}
      >
        <ListWrapper>
          {renderListSection(recordType, name, data, false)}
          <CardsContainer
            style={{
              display: "grid",
            }}
          >
            <GraphicalRecord graphData={data?.[recordType]} />
          </CardsContainer>
        </ListWrapper>
      </div>
    );
  };

  const renderQuestionnaireSection = (index) => {
    return (
      <ListWrapper key={index}>
        <ListSectionQuestionnaire>
          <ListDateTitlQuestionnaire>Questionnaire</ListDateTitlQuestionnaire>
          <AddButtonQuestionnaire
            onClick={() => navigate("questionnaire-type")}
          >
            Fill a Questionnaire
          </AddButtonQuestionnaire>
        </ListSectionQuestionnaire>
        <Grid container gap={2} mt={4}>
          {accordionData && accordionData["questionnaire"]?.data?.length && accordionData["questionnaire"].data
            .map((row, formIndex) => (
              <RecordItem
                row={row}
                fetchUrlForFile={() => {
                  const rowWithRecordType = {
                    ...row,
                    record_type: "questionnaire",
                  };
                  handleListItemClick(rowWithRecordType);
                }}
                menuItems={[]}
                reportType = {"questionnaire"}
                isShowDropdown ={false}
                index ={index}
              />

            )
            )
            .concat(
              accordionData["questionnaire"]?.total > itemsToShow && (
                <ListItemWrapper key="see-more">
                  <ListItemTop style={{ visibility: "hidden" }}>
                    hidden
                  </ListItemTop>
                  <SeeMoreWrapper
                    onClick={() => navigate("questionnaire-list")}
                  >
                    <SeeMoreText>See More</SeeMoreText>
                    <NextArrow src={INextArrow} />
                  </SeeMoreWrapper>
                  <ListItemDate style={{ visibility: "hidden" }}>
                    hidden
                  </ListItemDate>
                  <ListItemDate style={{ visibility: "hidden" }}>
                    hidden
                  </ListItemDate>
                </ListItemWrapper>
              )
            )}
        </Grid>
      </ListWrapper>
    );
  };

  const renderSection = (record_type, name, index) => {
    // Example rendering logic for a section
    // Adjust this to match how you render each section of your application
    switch (record_type) {
      case "scans":
        return (
          <ListWrapper key={`${record_type}-${index}`}>
            {renderListSection(record_type, name)}
            <Grid container gap={2} mt={4}>
              {
                accordionData[record_type]?.data?.length > 0 &&
                  accordionData[record_type]?.data
                    ?.slice(0, itemsToShow)
                    ?.map((row, index) => (
                      <RecordItem
                        reportType={"scan"}
                        fetchUrlForFile={() => handleScanClick(row?.id)}
                        setStoreData={setStoreData}
                        isShowDropdown={true}
                        menuItems={
                          row?.status === "RESTRICTED"
                            ? menuItemsWithStatusChange
                            : menuItems
                        }
                        row={row}
                        index={index}
                      />
                    ))
                .concat(
                  accordionData[record_type]?.total > itemsToShow && (
                    <ListItemWrapper key="see-more">
                      <ListItemTop style={{ visibility: "hidden" }}>
                        hidden
                      </ListItemTop>
                      <SeeMoreWrapper
                        onClick={() => {
                          navigate("all-records", {
                            state: {
                              recordType: record_type,
                              recordName: name,
                              patientID: selectedPatientId,
                            },
                          });
                        }}
                      >
                        <SeeMoreText>See More</SeeMoreText>
                        <NextArrow src={INextArrow} />
                      </SeeMoreWrapper>
                      <ListItemDate style={{ visibility: "hidden" }}>
                        hidden
                      </ListItemDate>
                      <ListItemDate style={{ visibility: "hidden" }}>
                        hidden
                      </ListItemDate>
                    </ListItemWrapper>
                  )
                )
              }
            </Grid>
          </ListWrapper>
        );
      default:
        return (
          <ListWrapper key={index}>
            {renderListSection(record_type, name)}
            <Grid container gap={2} mt={4}>
              {accordionData[record_type]?.data?.length > 0
                ? accordionData[record_type].data
                    ?.slice(0, itemsToShow)
                    ?.map((row, index) => (
                      <RecordItem
                        reportType={record_type}
                        fetchUrlForFile={() => handleListItemClick(row)}
                        setStoreData={setStoreData}
                        menuItems={
                          row?.status === "RESTRICTED" &&
                          record_type === "report"
                            ? menuItemsWithStatusChange
                            : menuItems
                        }
                        isShowDropdown={record_type === "report"}
                        row={row}
                        index={index}
                        thumbnailData={thumbnails}
                      />
                    ))
                .concat(
                  accordionData[record_type]?.total > itemsToShow && (
                      // Additional ListItemWrapper for the "See More" functionality
                      <ListItemWrapper key="see-more">
                        <ListItemTop style={{ visibility: "hidden" }}>
                          hidden
                        </ListItemTop>
                        <SeeMoreWrapper
                          onClick={() => {
                            navigate("all-records", {
                              state: {
                                recordType: record_type,
                                recordName: name,
                                patientID: selectedPatientId,
                              },
                            });
                          }}
                        >
                          <SeeMoreText>See More</SeeMoreText>
                          <NextArrow src={INextArrow} />
                        </SeeMoreWrapper>
                        <ListItemDate style={{ visibility: "hidden" }}>
                          hidden
                        </ListItemDate>
                        <ListItemDate style={{ visibility: "hidden" }}>
                          hidden
                        </ListItemDate>
                      </ListItemWrapper>
                    )
                  )
                : null}
            </Grid>
          </ListWrapper>
        );
    }
  };

  const renderSectionsWithLoadingIndicator = () => {
    // First, check if questionnaire data is loaded and available
    const questionnaireDataLoaded =
      !isLoading && accordionData && accordionData["questionnaire"]?.data?.length > 0;

    return (
      <>
        {accordionData && recordTypesArray.map(({ record_type, name }) => {
          // Skip rendering questionnaire section here since it's handled separately
          if (record_type === "questionnaire") return null;

          const sectionData = accordionData[record_type]?.data;
          const isLoading = loadedAccordions[record_type] === "loading";
          const hasData = sectionData && sectionData?.length > 0;

          return (
            <React.Fragment key={record_type}>
              {!isLoading && hasData && accordionData && (
                <div>{renderSection(record_type, name)}</div>
              )}
            </React.Fragment>
          );
        })}
        {/* Explicitly render questionnaire section if data is loaded */}
        {questionnaireDataLoaded && renderQuestionnaireSection()}

        {overallLoading && (
          <LoaderWrapper>
            <Container>
              <ShimmerContentBlock
                title
                text
                cta
                thumbnailWidth={82}
                thumbnailHeight={82}
              />
            </Container>
          </LoaderWrapper>
        )}
      </>
    );
  };

  return (
    <Container>
      <Breadcrumb links={breadCrubLinks} />
      <>
        <HeaderWrapper>
          <Header>Health Data</Header>
          <AddButton onClick={() => navigate("add-records")}>
            <AddIcon src={IAddIconWhite}></AddIcon>
            <AddText>Add Records</AddText>
          </AddButton>
          {/* <AddButton onClick={() => navigate('add-pacs')}>
          <AddIcon src={IAddIconWhite}></AddIcon>
          <AddText>Add Scans</AddText>
        </AddButton> */}
          <AddButton onClick={() => navigate("add-observation")}>
            <AddIcon src={IAddIconWhite}></AddIcon>
            <AddText>Add Data</AddText>
          </AddButton>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              display: "none",
              "@media (max-width: 767px)": {
                display: "flex",
              },
              "@media (max-width: 500px)": {
                display: "none",
              },
              backgroundColor: "#7ABA56",
              color: "#FFFFFF",
              borderRadius: "8px",
              width: "168px",
              height: "44px",
            }}
          >
            <AddIcon src={IAddIconWhite}></AddIcon>
            Add Options
          </Button>
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            sx={{
              display: "none",
              "@media (max-width: 500px)": {
                display: "flex",
              },
              backgroundColor: "#7ABA56",
              color: "#FFFFFF",
              borderRadius: "8px",
              width: "110px",
              height: "36px",
            }}
          >
            <AddIcon src={IAddIconWhite}></AddIcon>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openDropdown}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .MuiPaper-root": {
                borderRadius: "8px",
                color: "black",
              },
            }}
          >
            <MenuItem
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#404040",
                fontSize: "12px",
                width: "168px",
                borderBottom: "1px solid #F5FBFC",
              }}
              onClick={() => handleMenuClick("add-records")}
            >
              Records
            </MenuItem>
            <MenuItem
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#404040",
                fontSize: "12px",
                width: "168px",
                borderBottom: "1px solid #F5FBFC",
              }}
              onClick={() => handleMenuClick("add-observation")}
            >
              Data
            </MenuItem>
          </Menu>
        </HeaderWrapper>
        {showEmptyMessage && !overallLoading ? (
          <EmptyListMessage>No records found</EmptyListMessage>
        ) : (
          <>
            {renderSectionsWithLoadingIndicator()}

            {accordionData &&
              observationRecordTypes
                ?.filter(
                  ({ record_type }) => observationData[record_type]?.length > 0
                )
                .map(({ record_type, name }) =>
                  renderGraphList(record_type, name, observationData)
                )}
          </>
        )}
      </>
      {isShowhangeStatus ? (
        <ChangeStatusDialog
          open={isShowhangeStatus}
          onConfirm={(status) => onStatusAvailable(status)}
          testId={"test"}
          title={"Change file status"}
          discription={
            "Are you sure you want to change the status of the file?"
          }
          closeHandler={() => {
            setShowChangeStatus(false);
            setIsStatusChangeLoading(false);
          }}
          isLoding={issStatusChangeLoading}
        />
      ) : (
        ""
      )}
      <StyledModal open={open} onClose={closeModal}>
        <StyledModalContent>
          <StyledCloseButton onClick={() => setOpen(false)}>
            &times;
          </StyledCloseButton>
          {selectedRecord &&
            (["image/jpeg", "image/png"].includes(
              selectedRecord.content_type
            ) || selectedRecord?.record_type === "image" ? (
              <StyledImage
                src={selectedRecord?.url}
                alt={selectedRecord?.name}
              />
            ) : (
              <Worker workerUrl={workerUrl}>
                <Viewer fileUrl={selectedRecord?.url} />
              </Worker>
            ))}
        </StyledModalContent>
      </StyledModal>
      <SnackBar />
    </Container>
  );
};

export default PatientRecords;
