import { useState } from "react";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import ExistingRecipient from "./ExistingRecipient";
import MobileEmailVerification from "./MobileEmailVerification";
import SingleReport from "./SingleReport";
import { useEffect } from "react";
import { getRecipientListAction } from "../../../store/actions/records.action";
import { patientSelector, setSelectedPatient } from "../../../store/slice/patient.slice";
import { useLocation , useNavigate } from "react-router-dom";
import Loader from "../loader";
import Breadcrumb from "../../layout/Breadcrumb";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 1rem;
  font-size: 24px;
  color: #000;
  margin-bottom: 28px;
`;

const ShareReport = ({ recordName = "Share" }) => {
  const [recipientInfo, setRecipientInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const { selectedPatientId, mobileNumber, firstName, gender, dob } = useSelector(patientSelector);
  const dispatch = useDispatch();
  const location = useLocation();
  const { data } = location.state || {};
  const navigate = useNavigate();
  const isSmallScreen = useMediaQuery("(max-width:1050px)");

  const handleBackNavigation = (event) => {
    event.preventDefault();
    navigate(-1);
    dispatch(
      setSelectedPatient({
        id: selectedPatientId,
        phone_number: mobileNumber,
        first_name: firstName,
        gender: gender,
        dob: dob,
      })
    );
  };

  const breadCrubLinks = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Health Data",
      onClick: handleBackNavigation,
    },
    {
      label: "Scan",
    },
  ];

  const fetchRecipietRecord = async () => {
    setLoading(true);
    const payload = {
      patient_id: selectedPatientId,
      resource_id: data?.id || data?.fhir_id,
      report_type: data?.record_type,
      limit1: 10,
      page_number1: 1,
    };
    try {
      const action = await dispatch(
        getRecipientListAction({ payload: payload })
      );
      const response = action.payload;
      if (response && response.status === "success") {
        const responseData = action?.payload?.result || [];
        setRecipientInfo(responseData);
      } else {
        console.error("Failed to fetch recipient:", action.error);
      }
    } catch (error) {
      console.error("Failed to fetch Recipient:", error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    fetchRecipietRecord();
  }, []);
  return (
    <Grid m={3}>
      <Breadcrumb links={breadCrubLinks} />
      <TopHeader>
        <div>{recordName}</div>
      </TopHeader>

      <Box display="flex" gap={3} flexDirection={{ xs: "column", sm: "row" }}>
        <Box>
          <SingleReport
            name={data?.name || data?.patient_name}
            icon={data?.icon || data?.series[0]?.thumbnail}
            test_type={data?.record_type}
            upload_date={data?.upload_date}
          />
        </Box>

        <Box>
          <MobileEmailVerification
            reportId={data?.id || data?.fhir_id}
            recipientInfo={recipientInfo}
            setRecipientInfo={setRecipientInfo}
            recordType={data?.record_type}
            recordInfo={data}
          />
          <Box mt={3} mb={3} sx={{ border: "1px solid #D7EFF7" }} />
          <Typography mb={3}>Existing recipients</Typography>
          {!loading ? (
            <Grid container spacing={2}>
              {recipientInfo.length ? (
                recipientInfo.map((profile, index) => (
                  <Grid
                    item
                    xs={12}
                    md={recipientInfo.length === 1 || isSmallScreen ? 12 : 6}
                    key={`recipient-info-${index}`}
                  >
                    <ExistingRecipient
                      name={profile.name}
                      mobile={profile.phone_number}
                      email={profile.email}
                    />
                  </Grid>
                ))
              ) : (
                <Typography
                  sx={{
                    fontSize: "0.8rem",
                    color: "#808080",
                    textAlign: "center",
                    width: "100%",
                  }}
                  mt={3}
                >
                  No recipients found.
                </Typography>
              )}
            </Grid>
          ) : (
            <Loader />
          )}
        </Box>
      </Box>
    </Grid>
  );
};

export default ShareReport;
