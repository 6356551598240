import React, { useState, useEffect } from 'react';
import { CloseIcon, ProfileContainer, ProfileDetailsContainer, ProfileIcon, ProfileName, ProfileNumber, AddButton, CloseIconWrapper,
  PatientProfileIcon,
  PhoneNumber,
  SidebarImage, Container,
  PatientName, DobDate, DobYears, DetailItemDob, ArrowBackImage,
  DetailItem, GenderImage, GenderText, DobImage, DobText, NextArrowIcon, NavLink, TopHeader
 } from './style.components';
import { IBlueCloseIcon, IProfileImageSmall } from '../../../../assets';
import { useDispatch, useSelector } from "react-redux";
import { signoutAction } from "../../../../store/actions";
import { authSelector } from "../../../../store/slice/auth.slice";
import { IDobIcon, IGenderIcon, IProfileImage, ITodosIcon, IBackArrow } from "../../../../assets";
import { patientSelector } from "../../../../store/slice/patient.slice";
import { patientProfileSelector } from "../../../../store/slice/patient-profile.slice";
import { IProfileIcon, IRecordsIcon, IRemindersIcon, IConsultationIcon, INextArrow } from "../../../../assets";
import moment from 'moment';
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getPatientProfileAction,
} from "../../../../store/actions/patient-profile.action";

const capitalizeFirstLetter = string => {
  if (!string || typeof string !== 'string') return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const SideNav = ({ isOpen, closeSidebar }) => {
  const sidebarStyle = {
    width: '240px',
    position: 'fixed',
    top: '0',
    right: isOpen ? '0' : '-100vw',
    height: '100vh',
    transition: 'right 0.3s ease',
    zIndex: 100,
    backgroundColor: '#FFFFFF',
    boxShadow:' 0px 3px 6px #00000029',
    opacity: 1,
    padding: '24px'
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const state = useSelector(authSelector);

  const patientProfile = useSelector(patientProfileSelector);
  const { selectedPatientIdStore } = useSelector(patientSelector);
  const [selectedPatientId, setSelectedPatientId] = useState(selectedPatientIdStore);
  const patientId = searchParams.get("patient_id") || "";

  useEffect(() => {
    if (patientId) {
      setSelectedPatientId(patientId)
    } else {
      setSelectedPatientId(selectedPatientIdStore)
    }
  }, [navigate, searchParams]);

  useEffect(() => {
      if (selectedPatientId) {
          fetchPatientProfile(selectedPatientId);
      }
  }, [selectedPatientId, dispatch]);

  const fetchPatientProfile = async (id) => {
      try {
          await dispatch(getPatientProfileAction(id));
      } catch (error) {
          console.error("Error fetching patient profile:", error);
      }
  };

  const { basic_details } = patientProfile || {};
  const showGender = basic_details?.gender != null;
  const showDob = basic_details?.dob != null && basic_details?.dob !== '';

  const handleLogout = async () => {
    await dispatch(signoutAction());
    closeSidebar();
  };

  return (
    <div style={sidebarStyle}>
      <CloseIconWrapper>
        <CloseIcon src={IBlueCloseIcon} onClick={closeSidebar} />
      </CloseIconWrapper>

      <TopHeader onClick={() => navigate("/home")}>
        <ArrowBackImage src={IBackArrow} alt="back-arrow"/>
          Home
      </TopHeader>

      {/* <Container>
        <ProfileContainer>
          <ProfileIcon src={IProfileImageSmall}></ProfileIcon>
          <ProfileDetailsContainer>
            <ProfileName>{state?.userDetails?.first_name || "Dr. Porus Kapadia"}</ProfileName>
            <ProfileNumber>{state?.userDetails?.phone_number}</ProfileNumber>
          </ProfileDetailsContainer>
        </ProfileContainer>
        <AddButton onClick={handleLogout}>
          Logout / Change Country
        </AddButton>
      </Container> */}

      <PatientProfileIcon src={IProfileImage} alt="Profile Icon" />
      <PatientName>{basic_details?.first_name}</PatientName>
      <PhoneNumber>{basic_details?.phone_number}</PhoneNumber>
      {(showGender || showDob) ? (
      <>
        {showGender ? (
        <DetailItem>
          <GenderImage src={IGenderIcon} alt="Gender"></GenderImage>
          <GenderText>{capitalizeFirstLetter(basic_details?.gender)}</GenderText>
        </DetailItem>
        )
        : (
          <DetailItem style={{visibility: 'hidden'}}>
            <GenderImage src={IGenderIcon} alt="Gender"></GenderImage>
            <GenderText>Placeholder</GenderText>
          </DetailItem>
        )}
        {showDob ? (
        <DetailItemDob>
          <DobImage src={IDobIcon} alt="DOB"></DobImage>
          <DobText>
            <DobDate>{moment(basic_details?.dob).format('DD-MMM-YYYY') }</DobDate>
            <DobYears> ({moment().diff(moment(basic_details?.dob, 'YYYY-MM-DD'), 'years')} Years)</DobYears>
          </DobText>
        </DetailItemDob>
        ): (
          <DetailItemDob style={{visibility: 'hidden'}}>
            <DobImage src={IDobIcon} alt="DOB"></DobImage>
            <DobText>Placeholder</DobText>
          </DetailItemDob>
        )}
      </>
      ) : (
        <>
          <DetailItem style={{visibility: 'hidden'}}>
            <GenderImage src={IGenderIcon} alt="Gender"></GenderImage>
            <GenderText>Placeholder</GenderText>
          </DetailItem>
          <DetailItemDob style={{visibility: 'hidden'}}>
            <DobImage src={IDobIcon} alt="DOB"></DobImage>
            <DobText>Placeholder</DobText>
          </DetailItemDob>
        </>
      )}
      <NavLink 
        to={`/patient-records?patient_id=${selectedPatientId}`} 
        className={({ isActive }) => isActive ? 'active' : undefined}
        children={({ isActive }) => (
          <>
            <SidebarImage src={IRecordsIcon} alt="Health Data"/>
            Health Data
            {/* {isActive && <NextArrowIcon src={INextArrow} alt="Next Arrow"/>} */}
          </>
        )}
      />
      <NavLink 
        to={`/patient-profile?patient_id=${selectedPatientId}`}
        className={({ isActive }) => isActive ? 'active' : undefined}
        children={({ isActive }) => (
          <>
            <SidebarImage src={IProfileIcon} alt="Profile"/>
            Profile
            {/* {isActive && <NextArrowIcon src={INextArrow} alt="Next Arrow"/>} */}
          </>
        )}
      />
      <NavLink 
        to={`/patient-reminders?patient_id=${selectedPatientId}`}
        className={({ isActive }) => isActive ? 'active' : undefined}
        children={({ isActive }) => (
        <>
          <SidebarImage src={IRemindersIcon} alt="back-arrow"/>
          Reminders
          {/* {isActive && <NextArrowIcon src={INextArrow} alt="Next Arrow"/>} */}
        </>
        )}
      />
      <NavLink 
        to={`/patient-todos?patient_id=${selectedPatientId}`}
        className={({ isActive }) => isActive ? 'active' : undefined}
        children={({ isActive }) => (
        <>
          <SidebarImage src={ITodosIcon} alt="back-arrow"/>
          To-do
          {/* {isActive && <NextArrowIcon src={INextArrow} alt="Next Arrow"/>} */}
        </>
        )}
      />
      <NavLink 
        to={`/patient-consultations?patient_id=${selectedPatientId}`}
        className={({ isActive }) => isActive ? 'active' : undefined}
        children={({ isActive }) => (
        <>
          <SidebarImage src={IConsultationIcon} alt="back-arrow"/>
          Consultations
          {/* {isActive && <NextArrowIcon src={INextArrow} alt="Next Arrow"/>} */}
        </>
        )}
      />
    </div>
  );
};


export default SideNav;
