import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
  } from "@mui/material";
  import React, { useCallback, useEffect, useState } from "react";
  import { useNavigate } from "react-router-dom";
  import { useDispatch, useSelector } from "react-redux";
  import { IArrow, ILabReportThumb } from "../../../assets";
  import { SnackBar } from "../../../components/shared";
  import {
    getConsultationsAction,
  } from "../../../store/actions/consultations.actions";
  import { consultationSelector } from "../../../store/slice/consultations.slice";
  import { patientSelector } from "../../../store/slice/patient.slice";
  import {
    ActionImage,
    AddButton,
    Container,
    EditButton,
    EditDeleteWrapper,
    EmptyListMessage,
    Header,
    HeaderLeft,
    HeaderRight,
    HeaderWrapper,
    FullNameWrapper,
    ProfileImage,
    CaseNumber,
    UpdatedDate,
    StatusText,
    EncountersText,
    EncountersCount,
  } from "./style.components";
  import { format, parseISO } from 'date-fns';
  import { ShimmerTable } from "react-shimmer-effects";

function createData(id, updated_date, status, encounter_count) {
    return {id, updated_date, status, encounter_count };
}

const PatientConsultations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [consultationData, setConsultationData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { consultations } = useSelector(consultationSelector);
  const { selectedPatientId } = useSelector(patientSelector);

  const fetchAllConsultations = useCallback(async () => {
    if(selectedPatientId){
      setIsLoading(true);
      const payload = {
        "patient_id": selectedPatientId,
        "page_number": 0,
        "limit": 5,
        "only_logged_in_doctor":true
      }
      try {
        await dispatch(getConsultationsAction(payload));
      } catch (error) {
        console.error("Error fetching consultations:", error);
      } finally {
          setIsLoading(false);
      }
    }
  }, [dispatch, selectedPatientId]);

  const formulateConsultationsData = useCallback(() => {
    let data = [];
    consultations.forEach((consultation) => {
      const { id, updated_date, status, encounter_count } = consultation;
      data.push(createData(id, updated_date, status, encounter_count));
    });
    setConsultationData(data);
  }, [consultations]);

  useEffect(() => {
    if (consultations?.length > 0) {
      formulateConsultationsData();
    }
  }, [consultations, formulateConsultationsData]);

  useEffect(() => {
    if (selectedPatientId) {
      fetchAllConsultations();
    }
  }, [selectedPatientId, fetchAllConsultations]);

  return (
    <Container>
      <HeaderWrapper>
        <HeaderLeft>
          <Header>Consultations</Header>
        </HeaderLeft>
        <HeaderRight>
            <AddButton
                onClick={() =>
                    navigate("/encounters", {
                    state: { id: selectedPatientId },
                    })
                }
            >
                New Case
            </AddButton>
        </HeaderRight>
      </HeaderWrapper>
      {isLoading ? (
        <ShimmerTable row={5} />
      ) : (
        <>
          {consultationData?.length > 0 ? (
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody>
                  {consultationData.map((row) => {
                    const { id, updated_date, status, encounter_count } = row;
                    return (
                      <TableRow key={id}>
                        <TableCell align="left">
                          <FullNameWrapper>
                            <ProfileImage src={ILabReportThumb} alt="lab-report-thumb" />
                            <CaseNumber>Case no. {id}</CaseNumber>
                          </FullNameWrapper>
                        </TableCell>
                        <TableCell align="left">
                          <UpdatedDate>Updated: {format(parseISO(updated_date), 'dd-MMM-yyyy')}</UpdatedDate>
                        </TableCell>
                        <TableCell align="left">Status: <StatusText status={status}>{status}</StatusText></TableCell>
                        <TableCell align="left"><EncountersCount>{encounter_count}</EncountersCount> <EncountersText>Encounters</EncountersText></TableCell>
                        <TableCell>
                          <EditDeleteWrapper>
                            <EditButton
                              onClick={() =>
                                navigate("/encounters", {
                                  state: { id: id },
                                })
                              }
                            >
                              <ActionImage src={IArrow} alt="edit" />
                            </EditButton>
                          </EditDeleteWrapper>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <EmptyListMessage>No consultations data found</EmptyListMessage>
          )}
        </>
      )}
      <SnackBar />
    </Container>
  );
  
};
export default PatientConsultations;
