import React, { useState, useEffect, useCallback } from "react";
import DeleteConsultationsModal from '../delete-consultations-reminder/index'
import Switch from '@mui/material/Switch';

import {
    RemindersContainer,
    SugarLevelContainer,
    Title,
    ToggleButtonContainer,
    DateText,
    Edit,
    TitleContainer,
    DetailsContainer,
    SugarIcon,
    ContentContainer,
    ContentText,
    TimeContainer,
    Frequency,
    Text,
    Value,
    Days,
    Time,
    EditSection,
    Delete,
    ListButtonBase,
    ListAddTempWrapper,
    TodoContainer, EmptyListMessage,
    ButtonContainer,
     CloseButtonWrapper, ModalBody, ModalHeader, ButtonWrapper, SaveButton, NoButton, DeleteText
  } from "./style.components";
import {ICloseModal} from '../../../../assets/index'
import '../../../../utilities/customCalendar.css'
import { useNavigate } from "react-router-dom";

  const formatDate = (inputDate) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    
    const date = new Date(inputDate);
    const day = date.getDate().toString().padStart(2, '0');
    const monthIndex = date.getMonth();
    const year = date.getFullYear().toString().substring(2);
  
    return `${day}-${months[monthIndex]}-${year}`;
}

const formatCustomDates = (customDates) => {
    return customDates.map(dateString => {
      const date = new Date(dateString);
      return `${date.getDate()}-${date.toLocaleString('en-GB', { month: 'short' })}-${date.getFullYear()}`;
    }).join(', ');
}  

const convertTo12HourFormat = (timeString) => {
    const date = new Date(`1970-01-01 ${timeString}`);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
}

const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1) || '';
}

const formatDateTodo = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true };
    const date = new Date(dateString);
    return new Intl.DateTimeFormat('en-US', options).format(date);
};

const formatDays = (daysArray, frequency) => {
    if (frequency.toLowerCase() === 'daily') {
        return 'Everyday';
    }
    return daysArray.map(day => day.charAt(0).toUpperCase() + day.slice(1, 3)).join(', ');
}
const CustomReminderCard = ({reminder={}, icon={},index,getLocalReminders}) => {
    const [confirmModalOpen,setConfirmModalOpen]=useState(false)
    const [deleteId,setDeleteId]=useState('')
  const navigate = useNavigate();

    const confirmModal=()=>{
        setConfirmModalOpen(true)
        setDeleteId(index)
    }
  return (
    <>  
        <DeleteConsultationsModal getLocalReminders={getLocalReminders} deleteId={deleteId} confirmModalOpen={confirmModalOpen}  setConfirmModalOpen={setConfirmModalOpen}/>
          <SugarLevelContainer>
            <TitleContainer>
              <Title>{reminder?.reminder_type?.display_name}</Title>
              <EditSection>
              <ButtonContainer>
                    <Edit onClick={() => {
                    navigate("consultation/add-reminder",{state:{
                      "data":reminder,
                      "index":index
                    }
                    });
                  }}>Edit</Edit>
                </ButtonContainer>
                <ToggleButtonContainer onClick={confirmModal}>
                <img src={ICloseModal}/>
              </ToggleButtonContainer>
              </EditSection>
            </TitleContainer>
            <DateText>
             { reminder?.schedule_configuration?.start_date && reminder?.schedule_configuration?.end_date &&<> 
              {formatDate(reminder?.schedule_configuration?.start_date)} to{" "}
              {formatDate(reminder?.schedule_configuration?.end_date)}
              </>}
            </DateText>
            <DetailsContainer>
              <SugarIcon src={icon?.icon}></SugarIcon>
              <ContentContainer>
                <TimeContainer>
                  <Frequency>
                    <Text>Frequency</Text>
                    <Value>{capitalizeFirstLetter(reminder?.schedule_configuration?.frequency)}</Value>
                  </Frequency>
                    <Days>
                      <Text>Days</Text>
                      <Value>
                        { reminder?.schedule_configuration?.frequency !== "custom" ? formatDays(
                          reminder?.schedule_configuration?.schedules?.days || [],
                          reminder?.schedule_configuration?.frequency
                        ):'custom'}
                      </Value>
                    </Days>
                  <Time>
                    <Text>Time</Text>
                    <Value>
                      {!!reminder?.schedule_configuration?.schedules?.times?.length && reminder?.schedule_configuration?.schedules?.times
                        .map((time) => convertTo12HourFormat(time))
                        .join(", ")}{" "}
                    </Value>
                  </Time>
                </TimeContainer>
              </ContentContainer>
            </DetailsContainer>
          </SugarLevelContainer>
          </>
  );
};

export default CustomReminderCard;