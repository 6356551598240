import styled from "styled-components";
import { formatTimestamp } from "../../utilities/common";

const CardsContainer = styled.div`
  display: grid;
  gap: 12px;
  padding: 24px 15px;
  grid-template-columns: repeat(6, 1fr);
  @media (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (max-width: 950px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }

  .report-card {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    cursor: pointer;
    border: none;
    flex: 1;
    max-width: 100%;
    height: auto;
  }
`;

const ListItemWrapper = styled.div`
  padding: 0px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
  width: 100%;
`;

const ThumbImage = styled.img`
  height: 100%;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 8px;
  max-width: 100%;
`;

const ThumbSeriesImages = styled.div`
  position: relative;
  background: #ebf7fa;
  display: flex;
  justify-content: center;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  padding: 1rem 0.8rem;
  align-items: center;
`;

const ListItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 13px 9px;
  cursor: pointer;
  border: none;
  position: relative;
  font-size: 13px;
`;

const ThumbText = styled.div`
  position: absolute;
  bottom: 0;
  background: #30aed7;
  display: flex;
  justify-content: space-between;
  right: 0;
  color: #fff;
  left: 0;

  span {
    padding: 3px 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow-wrap: break-word;
    word-break: break-all;
  }
  .format {
    background: #0099cc;
    color: #fff;
    font-size: 13px;
    min-width: 26px;
  }
`;

const EmptyListMessage = styled.div`
  margin-top: 10px;
  padding: 20px;
  color: red;
  text-align: center;
`;

const LoadMoreWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const DisplaySeries = ({ recordInfo }) => {
  const screenWidth = window.innerWidth;
  const isMobileWidth = screenWidth <= 768;

  const handleScanClick = async (report) => {
    const url = isMobileWidth
      ? report?.mobile_viewer_url
      : report?.desktop_viewer_url;
    const newWindow = window.open("", "_blank");
    if (!newWindow) {
      alert(
        "Unable to open the new window. Please disable your popup blocker and try again."
      );
      return;
    }
    newWindow.location.href = url;
  };

  return (
    <>
      {recordInfo?.series && recordInfo.series.length > 0 ? (
        <>
          <LoadMoreWrapper>
            <CardsContainer>
              {recordInfo.series.map((row, index) => (
                <div
                  className="report-card"
                  style={{ position: "relative" }}
                  key={`display-series-${index}`}
                >
                  <ListItemWrapper onClick={() => handleScanClick(row)}>
                    <ThumbSeriesImages>
                      {row?.thumbnail && (
                        <ThumbImage
                          src={row?.thumbnail}
                          alt="records-thumbnail"
                        />
                      )}
                      <ThumbText>
                        <span>{row?.modality}</span>
                        <span className="format">
                          {row?.number_of_instances}
                        </span>
                      </ThumbText>
                    </ThumbSeriesImages>
                    <div>
                      <ListItem>
                        {formatTimestamp(row?.series_date)}
                      </ListItem>
                    </div>
                  </ListItemWrapper>
                </div>
              ))}
            </CardsContainer>
          </LoadMoreWrapper>
        </>
      ) : (
        <EmptyListMessage>No records found</EmptyListMessage>
      )}
    </>
  );
};

export default DisplaySeries;
