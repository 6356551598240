import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SnackBar } from "../../../components/shared";
import {
  getPatientProfileAction,
} from "../../../store/actions/patient-profile.action";
import { patientProfileSelector } from "../../../store/slice/patient-profile.slice";
import { patientSelector } from "../../../store/slice/patient.slice";
import {
  Container,
  Header,
  HeaderLeft,
  HeaderWrapper,
  ListWrapper,
  ListSection,
  ListDateTitle,
  ListItemWrapper,
  ListItem,
  ProfileHeader,
  ProfileLabel,
  ProfileData,
} from "./style.components";
import moment from 'moment';
import { ShimmerPostDetails } from "react-shimmer-effects";

const calculateAge = (dob) => {
    if (!dob) return null;
    const birthDate = moment(dob);
    const age = moment().diff(birthDate, 'years');
    return age;
};
const PatientProfile = () => {
    const dispatch = useDispatch();
    const patientProfile = useSelector(patientProfileSelector);
    const { selectedPatientId } = useSelector(patientSelector);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (selectedPatientId) {
            fetchPatientProfile(selectedPatientId);
        }
    }, [selectedPatientId, dispatch]);

    const fetchPatientProfile = async (id) => {
        setIsLoading(true);
        try {
            await dispatch(getPatientProfileAction(id));
        } catch (error) {
            console.error("Error fetching patient profile:", error);
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <Container><ShimmerPostDetails card cta variant="SIMPLE" /></Container>;
    }

    const sections = patientProfile?.health_history
    ? Object.keys(patientProfile.health_history).map(key => ({
        title: key.replace('_', ' ').charAt(0).toUpperCase() + key.replace('_', ' ').slice(1),
        value: patientProfile.health_history[key],
        }))
    : [];

    const { basic_details } = patientProfile || {};

    const capitalizeFirstLetter = (string) => {
        if (!string || typeof string !== 'string') return "";
        return string.charAt(0).toUpperCase() + string.slice(1);
    }
    const age = calculateAge(basic_details?.dob);

    return (
        <Container>
            <HeaderWrapper>
                <HeaderLeft>
                    <Header>Profile</Header>
                </HeaderLeft>
            </HeaderWrapper>
            <ProfileHeader>
                <div>
                    <ProfileLabel>Full Name</ProfileLabel>
                    <ProfileData>{(capitalizeFirstLetter(basic_details?.first_name) || "No data") + ' ' + (capitalizeFirstLetter(basic_details?.last_name) || '')}</ProfileData>
                </div>
                <div>
                    <ProfileLabel>Gender</ProfileLabel>
                    <ProfileData>{capitalizeFirstLetter(basic_details?.gender) || "No data"}</ProfileData>
                </div>
                <div>
                    <ProfileLabel>Date of Birth</ProfileLabel>
                    <ProfileData>
                        {moment(basic_details?.dob, 'YYYY-MM-DD').format('DD-MMM-YYYY') || "No data"} {age && `(${age} years)`}
                    </ProfileData>
                </div>
                <div>
                    <ProfileLabel>Email</ProfileLabel>
                    <ProfileData>{basic_details?.email || "No data"}</ProfileData>
                </div>
            </ProfileHeader>
            {sections.map((section, index) => (
                <ListWrapper key={index}>
                    <ListSection>
                        <ListDateTitle>{section.title}</ListDateTitle>
                    </ListSection>
                    <ListItemWrapper>
                        <ListItem>{section.value || "No data present"}</ListItem>
                    </ListItemWrapper>
                </ListWrapper>
            ))}
            <SnackBar />
        </Container>
    );
};

export default PatientProfile;
