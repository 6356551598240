import styled from "styled-components";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../../../utilities/constants"; 

export const Container = styled.div`
  margin: 24px 48px;
  border-radius: 12px;
  background-color: #F5FBFC;
  opacity: 1;
  min-height: calc(100vh - 72px);
  margin-bottom: 0;
  overflow-y: auto;
`;
export const Wrapper = styled.div`
  display: flex;
`;
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  color: #000000;
`;
export const Header = styled.div`
  color: #0099cc;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
`;
export const HeaderRight = styled.div`
  display: flex;
  flex: 0.6;
  justify-content: flex-end;
  align-items: center;
`;

export const AddButton = styled.button`
  padding: 10px;
  width: 150px;
  height: 40px;
  background: #7aba56 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 20px;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
`;
export const FinishButton = styled.button`
  padding: 10px;
  width: 150px;
  height: 40px;
  background: #CCCCCC 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  margin-top: 30px;
  margin-bottom: 20px;
  outline: none;
  border-style: hidden;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
`;
export const CaseNumber = styled.span`
    color: #000000;
    font-size: 20px;
    margin-right: 10px;
    font-weight: bold;
`;
export const ProfileImage = styled.img`
  margin-right: 10px;
`;
export const ArrowBackImage = styled.img`
  cursor: pointer;
`;
export const EmptyLineMessage = styled.div`
  margin-top: 40px;
  margin-left: 10px;
  color: #808080;
  font-size: 14px;
`;