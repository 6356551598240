import React, { useState, useEffect } from "react";
import {
  NavLink,
  SideNavbar,
  ProfileIcon,
  PhoneNumber,
  SidebarImage,
  Email,
  PatientName, DobDate, DobYears, DetailItemDob, TopHeader, ArrowBackImage,
  DetailItem, GenderImage, GenderText, DobImage, DobText, NextArrowIcon
} from "./style.components";
import { IBackArrow, IDobIcon, IGenderIcon, IProfileImage, ITodosIcon } from "../../../assets";
import { useSelector, useDispatch } from "react-redux";
import { patientSelector } from "../../../store/slice/patient.slice";
import { patientProfileSelector } from "../../../store/slice/patient-profile.slice";
import { IProfileIcon, IRecordsIcon, IRemindersIcon, IConsultationIcon, INextArrow } from "../../../assets";
import moment from 'moment';
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  getPatientProfileAction,
} from "../../../store/actions/patient-profile.action";
import { ShimmerContentBlock } from "react-shimmer-effects";

const capitalizeFirstLetter = string => {
  if (!string || typeof string !== 'string') return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const SideNav = () => {
  // const { mobileNumber, firstName, gender, dob, selectedPatientId } = useSelector(patientSelector);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const patientProfile = useSelector(patientProfileSelector);
  const { selectedPatientIdStore, previousPatient } = useSelector(patientSelector);
  const [selectedPatientId, setSelectedPatientId] = useState(selectedPatientIdStore);
  const patientId = searchParams.get("patient_id") || "";
  const [loading, setLoading] = useState(false);
  
  useEffect(() => {
    if (patientId) {
      setSelectedPatientId(patientId)
    } else {
      setSelectedPatientId(selectedPatientIdStore)
    }
  }, [navigate, searchParams]);

  useEffect(() => {
      if (selectedPatientId && selectedPatientId !== previousPatient?.id) {
          setLoading(true);
          fetchPatientProfile(selectedPatientId);
      }
  }, [selectedPatientId, dispatch]);

  const fetchPatientProfile = async (id) => {
    try {
      await dispatch(getPatientProfileAction(id));
    } catch (error) {
      console.error("Error fetching patient profile:", error);
    } finally {
      setLoading(false);
    }
  };

  const { basic_details } = patientProfile || {};
  const showGender = basic_details?.gender != null;
  const showDob = basic_details?.dob != null && basic_details?.dob !== '';

  return (
    <SideNavbar>
      <TopHeader onClick={() => navigate("/home")}>
        <ArrowBackImage src={IBackArrow} alt="back-arrow" />
        Home
      </TopHeader>
      {!loading ? (
        <>
          <ProfileIcon src={IProfileImage} alt="Profile Icon" />
          <PatientName title={basic_details?.first_name}>
            {basic_details?.first_name}
          </PatientName>
          <PhoneNumber title={basic_details?.phone_number}>
            {basic_details?.phone_number}
          </PhoneNumber>
          <Email title={basic_details?.email}>{basic_details?.email}</Email>
          {showGender || showDob ? (
            <>
              {showGender ? (
                <DetailItem>
                  <GenderImage src={IGenderIcon} alt="Gender"></GenderImage>
                  <GenderText>
                    {capitalizeFirstLetter(basic_details?.gender)}
                  </GenderText>
                </DetailItem>
              ) : (
                <DetailItem style={{ visibility: "hidden" }}>
                  <GenderImage src={IGenderIcon} alt="Gender"></GenderImage>
                  <GenderText>Placeholder</GenderText>
                </DetailItem>
              )}
              {showDob ? (
                <DetailItemDob>
                  <DobImage src={IDobIcon} alt="DOB"></DobImage>
                  <DobText>
                    <DobDate>
                      {moment(basic_details?.dob).format("DD-MMM-YYYY")}
                    </DobDate>
                    <DobYears>
                      {" "}
                      (
                      {moment().diff(
                        moment(basic_details?.dob, "YYYY-MM-DD"),
                        "years"
                      )}{" "}
                      Years)
                    </DobYears>
                  </DobText>
                </DetailItemDob>
              ) : (
                <DetailItemDob style={{ visibility: "hidden" }}>
                  <DobImage src={IDobIcon} alt="DOB"></DobImage>
                  <DobText>Placeholder</DobText>
                </DetailItemDob>
              )}
            </>
          ) : (
            <>
              <DetailItem style={{ visibility: "hidden" }}>
                <GenderImage src={IGenderIcon} alt="Gender"></GenderImage>
                <GenderText>Placeholder</GenderText>
              </DetailItem>
              <DetailItemDob style={{ visibility: "hidden" }}>
                <DobImage src={IDobIcon} alt="DOB"></DobImage>
                <DobText>Placeholder</DobText>
              </DetailItemDob>
            </>
          )}{" "}
        </>
      ) : (
        <ShimmerContentBlock
          title
          text
          cta
          thumbnailWidth={1}
          thumbnailHeight={1}
          style={{ height: '50px'}} 
        />
      )}
      <NavLink
        to={`/patient-records?patient_id=${selectedPatientId}`}
        className={({ isActive }) => (isActive ? "active" : undefined)}
        children={({ isActive }) => (
          <>
            <SidebarImage src={IRecordsIcon} alt="Health Data" />
            Health Data
            {isActive && <NextArrowIcon src={INextArrow} alt="Next Arrow" />}
          </>
        )}
      />
      <NavLink
        to={`/patient-profile?patient_id=${selectedPatientId}`}
        className={({ isActive }) => (isActive ? "active" : undefined)}
        children={({ isActive }) => (
          <>
            <SidebarImage src={IProfileIcon} alt="Profile" />
            Profile
            {isActive && <NextArrowIcon src={INextArrow} alt="Next Arrow" />}
          </>
        )}
      />
      <NavLink
        to={`/patient-reminders?patient_id=${selectedPatientId}`}
        className={({ isActive }) => (isActive ? "active" : undefined)}
        children={({ isActive }) => (
          <>
            <SidebarImage src={IRemindersIcon} alt="back-arrow" />
            Reminders
            {isActive && <NextArrowIcon src={INextArrow} alt="Next Arrow" />}
          </>
        )}
      />
      <NavLink
        to={`/patient-todos?patient_id=${selectedPatientId}`}
        className={({ isActive }) => (isActive ? "active" : undefined)}
        children={({ isActive }) => (
          <>
            <SidebarImage src={ITodosIcon} alt="back-arrow" />
            To-do
            {isActive && <NextArrowIcon src={INextArrow} alt="Next Arrow" />}
          </>
        )}
      />
      <NavLink
        to={`/patient-consultations?patient_id=${selectedPatientId}`}
        className={({ isActive }) => (isActive ? "active" : undefined)}
        children={({ isActive }) => (
          <>
            <SidebarImage src={IConsultationIcon} alt="back-arrow" />
            Consultations
            {isActive && <NextArrowIcon src={INextArrow} alt="Next Arrow" />}
          </>
        )}
      />
    </SideNavbar>
  );
};

export default SideNav;