import styled from "styled-components";
import {
  COLOR_PRIMARY_LIGHT,
  DEVICE,
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../utilities/constants";

export const ParentContainer = styled.div`
  display: flex;
  // padding: 40px;
  padding-bottom: 20px;
  background-color: ${COLOR_PRIMARY_LIGHT};
  min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
`;

export const MainBody = styled.div`
  width: 100%;
  overflow-y: auto;

  @media ${DEVICE.laptop} {
    padding: 0px 20px;
  }
`;
