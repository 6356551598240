import styled from "styled-components";
import {
  DEVICE,
  HEADER_HEIGHT,
} from "../../../utilities/constants";

export const Container = styled.div`
  margin: 24px 48px;
  border-radius: 12px;
  background-color: #F5FBFC;
  opacity: 1;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  margin-bottom: 0;
  overflow-y: auto;

  @media ${DEVICE.tablet} {
    margin: 24px 0;
  }
`;
export const LeftHeaderSection = styled.div`
    display: flex;
    align-items: center;
`;
export const TopHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-size: 24px;
    color: #0099CC;
    margin-bottom: 28px;

    @media ${DEVICE.tablet} {
      font-size: 20px;
    }
`;
export const ArrowBackImage = styled.img`
    cursor: pointer;
`;
export const AddButton = styled.button`
  padding: 10px;
  min-width: 150px;
  height: 35px;
  background: #7aba56 0% 0% no-repeat padding-box;
  border-radius: 8px;
  color: #ffffff;
  outline: none;
  border-style: hidden;
  font-size: 14px;
  cursor: pointer;
  margin-right: 10px;

  @media ${DEVICE.tablet} {
    margin-right: 0px;
    margin-left: 10px;
  }
`;
export const CardsContainerForms = styled.div`
  display: grid;
  grid-gap: 24px;
  padding: 24px 15px;
  background-color: #FFFFFF;
  grid-template-columns: repeat(6, 1fr);

  @media ${DEVICE.tablet} {
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 12px;
  }

  @media ${DEVICE.mobileL} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px;
  }
`;
export const LoaderWrapper = styled.div`
  margin: 15px;
`;
export const ThumbImageForms = styled.img`
  width: 66px;
  height: 66px;
  object-fit: cover;
  padding: 37px;
  background-color: #EBF7FB;
  border-radius: 8px;
`;
export const ListItemDateForms = styled.div`
  color: #808080;
  font-size: 11px;
`;
export const ListItemForms = styled.div`
  color: #404040;
  font-size: 11px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 12px;
  min-height: 15px;
  flex: 1 1 calc(20% - 24px);
  max-width: 140px;
`;
export const ListItemWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  cursor: pointer;
  padding: 15px;
  margin: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #F5FBFC;
    transform: translateY(-5px);
  }
`;
export const CardDetailsWrapper = styled.div`
  margin-left: 10px;
  margin-top: 3px;
`;
export const EmptyListMessage = styled.div`
  margin-top: 10px;
  padding: 20px;
  color: red;
  text-align: center;
`;