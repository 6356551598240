import React, { useCallback, useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SnackBar } from "../../components/shared";
import { ShimmerTable } from "react-shimmer-effects";
import { AddPatientModal } from "../../components/layout";
import useIsAuthenticated from "../../hooks/useIsAuthenticated";
import {
  getPatientsAction,
  addPatientAction,
  getPatientsByNumberAction,
} from "../../store/actions/patient.action";
import { patientSelector, setSelectedPatient } from "../../store/slice/patient.slice";
import {
  ISearch, IBlueArrow, IProfileImageSmall, IEmailIcon, IDobIcon, IGenderIcon, IAddIconWhite
} from "../../assets";
import {
  Container, HeaderWrapper, Header, HeaderLeft, HeaderRight, SearchWrapper, 
  SearchInput, SearchButton, SearchImage, AddButton, ProfileImage, 
  PatientContainer, PatientCard, ProfileSection, PatientInfo, PhoneNumber, 
  Name, ArrowImage, DetailSection, DetailItem, EmailSection, Email, EmailIcon, EmptyListMessage,
  GenderImage, GenderText, DobImage, DobText, DobDate, DobYears, AddIcon, AddText, AddButtonMobile
} from "./style.components";
import moment from 'moment';
import { TextField, InputAdornment, Box, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';

const capitalizeFirstLetter = string => {
  if (!string || typeof string !== 'string') return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const createData = (id, first_name, last_name, gender, phone_number, dob, email) => ({id, first_name, last_name, gender, phone_number, dob, email});

const PatientList = () => {
  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();
  const { patients, searchedPatient } = useSelector(patientSelector);
  const dispatch = useDispatch();
  const [patientData, setPatientData] = useState(null);
  const [showAddPatientModal, setShowAddPatientModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobileWidth, setMobileWidth] = useState(false);
  useEffect(() => {
    function handleResize() {
      const screenWidth = window.innerWidth;
      if (screenWidth <= 768) {
        setMobileWidth(true);
      }else{
        setMobileWidth(false);
      }
    }
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobileWidth]);  

  useEffect(() => {
    if (!isAuthenticated) navigate("/login");
  }, [navigate, isAuthenticated]);

  const fetchAllPatients = useCallback(async () => {
    setIsLoading(true);
    try {
        await dispatch(getPatientsAction());
    } catch (error) {
        console.error("Error fetching patients:", error);
    } finally {
        setIsLoading(false);
    }
  }, [dispatch]);

  const fetchSearchPatient = async () => {
    setIsLoading(true);
    try {
      if (searchQuery !== "") {
        const action = await dispatch(
          getPatientsByNumberAction({ payload: { search: searchQuery } })
        );
        if (action?.payload?.status === "success") {
          setPatientData(action?.payload?.data?.records);
        } else {
          setPatientData([]);
        }
      } else {
        patients && setPatientData(patients);
      }
    } catch (err) {
      console.log("error occured");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearchClick = useCallback(async () => {
    await fetchSearchPatient();
  }, [searchQuery, dispatch]);


  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
    if(query === "") {
      setPatientData(patients);
    }
  };

  const formulatePatientsData = useCallback(() => {
    if (patients) {
      const data = patients.map(patient => createData(patient.id, patient.first_name, patient.last_name, patient.gender, patient.phone_number, patient.dob, patient.email));
      setPatientData(data);
    }
  }, [patients]);

  useEffect(() => {
    formulatePatientsData();
  }, [patients, formulatePatientsData]);

  useEffect(() => {
    if(isAuthenticated) fetchAllPatients();
  }, [fetchAllPatients]);

  const onAddPatient = async (patientPayload) => {
    setIsLoading(true);
    try {
      setShowAddPatientModal(false);
      const action = await dispatch(addPatientAction(patientPayload));
      const response = action.payload;
      if (response && response.status === "success") {
        setIsLoading(false);
        await fetchAllPatients();
      }
      else {
          console.error("Failed to add patient:", action.error);
      }
    } catch (error) {
        console.error("Failed to add patient:", error);
    } finally {
        setIsLoading(false);
    }
  };
  const handleClear =() => {
    setSearchQuery('');
    setPatientData(patients);
  }

  const handlePatientClick = (id, phone_number, first_name, gender, dob) => {
    dispatch(setSelectedPatient({ id, phone_number, first_name, gender, dob }));
    navigate(`/patient-records?patient_id=${id}`);
};
const showClearButton = useMemo(() => searchQuery.length > 0, [searchQuery]);
const handleKeyDown = useCallback((e) => {
  if (e.key === 'Enter') {
    handleSearchClick();
  }
}, [handleSearchClick]);

  return (
    <Container>
      {isMobileWidth ? (
        <>
          <HeaderWrapper>
            <HeaderLeft>
              <Header>Patients ({patientData?.length})</Header>
            </HeaderLeft>
            <Box display="flex" width={"100%"} justifyContent="end">
              <TextField
                variant="outlined"
                placeholder="Search..."
                value={searchQuery}
                title="Search by name, mobile number or email"
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: (
                    <>
                    {showClearButton && (
                      <IconButton onClick={handleClear} edge="end">
                        <ClearIcon />
                      </IconButton>
                    )}
                    <InputAdornment position="end">
                      <SearchIcon sx={{ color: "#7aba57", cursor: "pointer" }} onClick={handleSearchClick}/>
                    </InputAdornment>
                    </>
                  ),
                }}
                sx={{
                  marginRight: 2,
                  "& .MuiOutlinedInput-root": {
                    height: "45px",
                    background: "#FFF",
                  },
                  "& .MuiInputBase-input": {
                    padding: "0 20px",
                  },
                  width: "70%",
                }}
              />
              <AddButtonMobile
                onClick={() => {
                  setShowAddPatientModal(true);
                }}
              >
                <AddIcon src={IAddIconWhite}></AddIcon>
              </AddButtonMobile>
            </Box>
          </HeaderWrapper>
        </>
      ) : (
        <HeaderWrapper>
          <HeaderLeft>
            <Header>Patients ({patientData?.length})</Header>
          </HeaderLeft>
          <Box display="flex" width={"100%"} justifyContent="end">
            <TextField
              variant="outlined"
              placeholder="Search by name, mobile number or email"
              value={searchQuery}
              onChange={handleSearchChange}
              onKeyDown={handleKeyDown}
              InputProps={{
                endAdornment: (
                  <>
                    {showClearButton && (
                      <IconButton onClick={handleClear} edge="end">
                        <ClearIcon />
                      </IconButton>
                    )}
                    <InputAdornment position="end">
                      <SearchIcon sx={{ color: "#7aba57", cursor: "pointer" }} onClick={handleSearchClick}/>
                    </InputAdornment>
                  </>
                ),
              }}
              sx={{
                marginRight: 2,
                "& .MuiOutlinedInput-root": {
                  height: "45px",
                  background: "#FFF",
                },
                "& .MuiInputBase-input": {
                  padding: "0 20px", // Adjusting padding to fit the smaller height
                },
                width: "50%",
              }}
            />
            <AddButton
              onClick={() => {
                setShowAddPatientModal(true);
              }}
            >
              <AddIcon src={IAddIconWhite}></AddIcon>
              <AddText>Add New</AddText>
            </AddButton>
          </Box>
        </HeaderWrapper>
      )}
      {isLoading ? (
        <ShimmerTable row={5} />
      ) : (
        <>
          {!searchedPatient && patientData?.length > 0 ? (
            <PatientContainer>
              {patientData.map((patient, index) => {
                const {
                  id,
                  first_name,
                  last_name,
                  gender,
                  phone_number,
                  dob,
                  email,
                } = patient;
                const showGender = gender != null;
                const showDob = dob != null && dob !== "";
                const showEmail = email != null && email !== "";
                return (
                  <PatientCard
                    key={index + 1}
                    onClick={() =>
                      handlePatientClick(
                        id,
                        phone_number,
                        first_name,
                        gender,
                        dob
                      )
                    }
                  >
                    <ProfileSection>
                      <ProfileImage
                        src={IProfileImageSmall}
                        alt="ProfileImage"
                      />
                      <PatientInfo>
                        <Name>
                          {first_name} {last_name}
                        </Name>
                        <PhoneNumber>{phone_number}</PhoneNumber>

                        {showGender || showDob ? (
                          <>
                            {showGender ? (
                              <DetailItem>
                                <GenderImage
                                  src={IGenderIcon}
                                  alt="Gender"
                                ></GenderImage>
                                <GenderText>
                                  {capitalizeFirstLetter(gender)}
                                </GenderText>
                              </DetailItem>
                            ) : (
                              <DetailItem style={{ visibility: "hidden" }}>
                                <GenderImage
                                  src={IGenderIcon}
                                  alt="Gender"
                                ></GenderImage>
                                <GenderText>Placeholder</GenderText>
                              </DetailItem>
                            )}
                            {showDob ? (
                              <DetailItem>
                                <DobImage src={IDobIcon} alt="DOB"></DobImage>
                                <DobText>
                                  <DobDate>
                                    {moment(dob).format("DD-MMM-YYYY")}
                                  </DobDate>
                                  <DobYears>
                                    {" "}
                                    (
                                    {moment().diff(
                                      moment(dob, "YYYY-MM-DD"),
                                      "years"
                                    )}{" "}
                                    Years)
                                  </DobYears>
                                </DobText>
                              </DetailItem>
                            ) : (
                              <DetailItem style={{ visibility: "hidden" }}>
                                <DobImage src={IDobIcon} alt="DOB"></DobImage>
                                <DobText>Placeholder</DobText>
                              </DetailItem>
                            )}
                          </>
                        ) : (
                          <>
                            <DetailItem style={{ visibility: "hidden" }}>
                              <GenderImage
                                src={IGenderIcon}
                                alt="Gender"
                              ></GenderImage>
                              <GenderText>Placeholder</GenderText>
                            </DetailItem>
                            <DetailItem style={{ visibility: "hidden" }}>
                              <DobImage src={IDobIcon} alt="DOB"></DobImage>
                              <DobText>Placeholder</DobText>
                            </DetailItem>
                          </>
                        )}

                        {showEmail ? (
                          <EmailSection>
                            <EmailIcon src={IEmailIcon} alt="Email" />
                            <Email>{email}</Email>
                          </EmailSection>
                        ) : (
                          <EmailSection style={{ visibility: "hidden" }}>
                            <EmailIcon src={IEmailIcon} alt="Email" />
                            <Email>Placeholder</Email>
                          </EmailSection>
                        )}
                      </PatientInfo>
                      {/* <ArrowImage src={IBlueArrow} alt="BlueArrow" onClick={() => handlePatientClick(id, phone_number)} /> */}
                    </ProfileSection>
                  </PatientCard>
                );
              })}
            </PatientContainer>
          ) : (
            <EmptyListMessage>No patients data found</EmptyListMessage>
          )}
        </>
      )}
      <AddPatientModal
        open={showAddPatientModal}
        handleClose={() => setShowAddPatientModal(false)}
        handleSave={onAddPatient}
      />
      <SnackBar />
    </Container>
  );
};
export default PatientList;
