import React, { useEffect, useState } from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MetaInfo from "../MetaInfo";
import DisplaySeries from "./DisplaySeries";
import FileInfoSection from "./FileInfoSection";
import "./InfoSection.css";
import { getStudyInfo } from "../../store/actions/records.action";
import { Loader } from "../shared";
import { removeSpecialCharacters } from "../../utilities/common";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../layout/Breadcrumb";
import { ShimmerContentBlock } from "react-shimmer-effects";
import {  patientSelector, setSelectedPatient } from "../../store/slice/patient.slice";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.6rem;
  font-color: "#000";
  margin-top: 1rem;
`;

const InfoSection = () => {
  const [dicomInfo, setDicomInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();
  const { studyFhirId } = location.state || {};
  const navigate = useNavigate();
  const { selectedPatientId, mobileNumber, firstName, gender, dob } = useSelector(patientSelector);

  const fetchStudyDetails = async () => {
    setLoading(true);
    try {
      const action = await dispatch(getStudyInfo({ fhir_id: studyFhirId }));
      const response = action.payload;
      if (response && response.status === "success") {
        const responseData = action?.payload?.data || [];
        setDicomInfo(responseData);
      } else {
        console.error("Failed to fetch study info:", action.error);
      }
    } catch (error) {
      console.error("Failed to fetch study info:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudyDetails();
  }, []);

  const handleBackNavigation = (event) => {
    event.preventDefault();
    navigate(-1);
    dispatch(
      setSelectedPatient({
        id: selectedPatientId,
        phone_number: mobileNumber,
        first_name: firstName,
        gender: gender,
        dob: dob,
      })
    );
  };

  const breadCrubLinks = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Health Data",
      onClick: handleBackNavigation,
    },
    {
      label: "Scan",
    },
  ];
  return (
    <>
      <Box m={3}>
        <Breadcrumb links={breadCrubLinks} />
        <TopHeader>Studies</TopHeader>
        {!loading && dicomInfo ? (
          <Box
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems="stretch"
          >
            <Grid item display="flex">
              <Paper
                className="box-wrapper"
                sx={{
                  background: "#EBF7FB",
                  borderRadius: {
                    xs: "1rem 1rem 0 0",
                    md: "1rem 0 0 1rem",
                  },
                  paddingBottom: 2,
                  flexDirection: "column",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  className="info-header"
                  sx={{
                    borderTopLeftRadius: "1rem",
                    borderTopRightRadius: { xs: "1rem", sm: 0 },
                  }}
                >
                  <Typography variant="h6" sx={{ color: "#FFF" }}>
                    {removeSpecialCharacters(
                      dicomInfo?.study_info?.patient_name
                    )}
                  </Typography>
                </Box>
                <Box>
                  <FileInfoSection recordInfo={dicomInfo?.study_info} />
                  <Box
                    display="flex"
                    m={2}
                    p={2}
                    flexDirection="column"
                    sx={{ background: "#FFF", borderRadius: 4 }}
                  >
                    <Box className="series-text" pl={2}>
                      Series({dicomInfo?.study_info?.series.length})
                    </Box>
                    <Box display="flex">
                      <DisplaySeries recordInfo={dicomInfo?.study_info} />
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item display="flex">
              <MetaInfo recordInfo={dicomInfo} />
            </Grid>
          </Box>
        ) : (
          <Box m={2}>
            <ShimmerContentBlock
              title
              text
              cta
              thumbnailWidth={100}
              thumbnailHeight={100}
            />
          </Box>
        )}
      </Box>
    </>
  );
};

export default InfoSection;
