  import React, { useEffect, useState } from "react";
  import { useNavigate, useLocation } from "react-router-dom";
  import { IBackArrow, ILabReportThumb } from "../../../../assets";
  import {
    FinishButton,
    AddButton,
    Container,
    Header,
    TopHeader,
    HeaderRight,
    HeaderWrapper,
    ProfileImage,
    CaseNumber,
    ArrowBackImage,
    EmptyLineMessage,
  } from "./style.components";

const PatientEncounters = () => {
  const navigate = useNavigate();
  const [patientID, setPatientID] = useState(null);

  const location = useLocation();

  useEffect(() => {
    setPatientID(location.state?.id);
    localStorage.removeItem("reminders");
  }, [location]);

  return (
    <Container>
      <TopHeader>
        <ArrowBackImage src={IBackArrow} alt="back-arrow" 
          onClick={() => {
            navigate(-1);
          }} 
        />
          New Case
      </TopHeader>
      <HeaderWrapper>
          <Header>
            <ProfileImage src={ILabReportThumb} alt="lab-report-thumb" />
            <CaseNumber>Case no. {patientID}</CaseNumber>
          </Header>
        <HeaderRight>
          <FinishButton
                  onClick={() =>
                      navigate("/new-encounter", {
                      state: { id: patientID },
                      })
                  }
              >
              Finish Case
          </FinishButton>
          <AddButton
              onClick={() =>
                  navigate("/new-encounter", {
                  state: { id: patientID },
                  })
              }
          >
              New Encounter
          </AddButton>
        </HeaderRight>
      </HeaderWrapper>
      <EmptyLineMessage>This case is empty, please begin by adding a new encounter.</EmptyLineMessage>
    </Container>
  );
};
export default PatientEncounters;
