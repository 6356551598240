import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import {
  IBackArrow,
  IRemoveIcon,
  IAttachIcon,
  ILogoWhite,
  IAttachReports,
  IConsultation,
  IDiagnosis,
  IMicIcon,
  INextSteps,
  IPersonalInfo,
  ITranscription,
  IVitals,
  IRemindersIcon,
} from "../../../../assets";
import * as Styled from "./style.components";
import {
  AttachRecordsModal,
  ChatWidgetComponent,
  AudioRecordModal,
  AddRecords,
} from "../../../../components/layout/";
import { SnackBar } from "../../../../components/shared";
import {
  runAssessmentAction,
  callHemeBotAction,
  getSingleRecordAction,
  getPatientProfileAction,
  generateDocAction,
  getRecordTypeAction,
  getObservationTypesAction,
} from "../../../../store/actions";
import { assesmentSelector } from "../../../../store/slice/assessment.slice";
import { hemebotSelector } from "../../../../store/slice/hemebot.slice";
import { patientProfileSelector } from "../../../../store/slice/patient-profile.slice";
import { generateDocSelector } from "../../../../store/slice/generate-doc.slice";
import { recordTypeSelector } from "../../../../store/slice/record-type.slice";
import { resetState } from "../../../../store/slice/hemebot.slice";
import { resetAiSummaries } from "../../../../store/slice/single-record.slice";
import moment from "moment";
import { ShimmerText } from "react-shimmer-effects";
import html2pdf from "html2pdf.js";
import { Loader } from "../../../../components/shared";
import CustomReminderCard from "../custom-reminder-card/index";
import {
  EmptyListMessage,
  RemindersContainer,
} from "../custom-reminder-card/style.components";

<script src="https://cdnjs.cloudflare.com/ajax/libs/html2pdf.js/0.9.3/html2pdf.bundle.min.js"></script>;

const formattedData = (data) => {
  if (typeof data !== "string") return "";
  return data.replace(/,\n/g, "\n");
};

const calculateAge = (dob) => {
  if (!dob) return null;
  const birthDate = moment(dob);
  const age = moment().diff(birthDate, "years");
  return age;
};

const NewEncounter = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const assessment = useSelector(assesmentSelector);
  const patientProfile = useSelector(patientProfileSelector);
  const answer = useSelector(hemebotSelector) || {};
  const [patientID, setPatientID] = useState("");
  const [patientName, setPatientName] = useState("");
  const [patientAge, setPatientAge] = useState("");
  const [patientHeight, setPatientHeight] = useState("");
  const [patientWeight, setPatientWeight] = useState("");
  const [bloodPressure, setBloodPressure] = useState("");
  const [heartRate, setHeartRate] = useState("");
  const [spo2, setSpo2] = useState("");
  const [respiratoryRate, setRespiratoryRate] = useState("");
  const [temperature, setTemperature] = useState("");
  const [randomBloodSugar, setRandomBloodSugar] = useState("");
  const [isGenerateEnabled, setIsGenerateEnabled] = useState(false);
  const [gender, setGender] = useState("");
  const [patientHistory, setPatientHistory] = useState("");
  const [symptoms, setSymptoms] = useState("");
  const [physicalExamination, setPhysicalExamination] = useState("");
  const [otherNotes, setOtherNotes] = useState("");
  const [showAddRecordsModal, setShowAddRecordsModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSummaryLoading, setIsSummaryLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [assessmentResponse, setAssessmentResponse] = useState({});
  const [isTranscriptionProgress, setIsTranscriptionProgress] =
    useState("start");
  const [singleRecordResponse, setSingleRecordResponse] = useState([]);
  const [transcriptionResponse, setTranscriptionResponse] = useState("");
  const location = useLocation();
  const { infoResponse, transcript } = location.state || {};
  const [userSentMessage, setUserSentMessage] = useState(false);
  const [assessmentRun, setAssessmentRun] = useState(false);
  const [chatHistory, setChatHistory] = useState([]);
  const [systemPrompt, setSystemPrompt] = useState("");
  const [pdfBlob, setPdfBlob] = useState(null);
  const [shouldOpenDocument, setShouldOpenDocument] = useState(false);
  const aiSummaries =
    useSelector((state) =>
      state.singleRecord.singleRecordObj
        ? state.singleRecord.singleRecordObj.aiSummaries
        : []
    ) || [];
  const documentData = useSelector(generateDocSelector);
  const [showAudioRecordModal, setshowAudioRecordModal] = useState(false);
  const [recordTypesArray, setRecordTypesArray] = useState([]);
  const recordTypes = useSelector((state) => recordTypeSelector(state));
  const fileInputRef = useRef(null);
  const [isPdfMode, setIsPdfMode] = useState(false);
  const [differentialDiagnosis, setDifferentialDiagnosis] = useState("");
  const [showSendRecordsModal, setShowSendRecordsModal] = useState(false);
  const [nextSteps, setNextSteps] = useState("");
  const [temperatureUnit, setTemperatureUnit] = useState("F");
  const [heightUnit, setHeightUnit] = useState("cms");
  const [weightUnt, setWeightUnit] = useState("kgs");
  const [localreminders, setLocalReminders] = useState([]);
  const summaryRef = useRef();
  const transcriptionRef = useRef();
  const prescriptionRef = useRef();
  const [pdfFiles, setPdfFiles] = useState({
    summary: null,
    transcription: null,
    prescription: null,
  });
  const [patientDetails, setPatientDetails] = useState({});
  const contentStyle = {
    display: isPdfMode ? "block" : "none",
  };
  const [observationTypesArray, setObservationTypesArray] = useState([]);

  const styles = {
    mainContainer: {
      padding: "20px",
      maxWidth: "600px",
      margin: "auto",
    },
    header: {
      padding: "10px 20px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
    },
    leftWrapper: {
      flex: "1",
    },
    title: {
      color: "#0099CC",
    },
    subTitle: {
      color: "#808080",
    },
    consultationSummary: {
      backgroundColor: "#F5FBFC",
      padding: "15px 20px",
      borderRadius: "5px",
      margin: "20px 0",
    },
    sectionTitle: {
      fontWeight: "bold",
      textAlign: "center",
      backgroundColor: "#0099CC",
      color: "#ffffff",
      padding: "10px",
    },
    personalInfoWrapper: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    contentBlock: {
      margin: "10px 0",
    },
    button: {
      display: "block",
      width: "100%",
      padding: "10px",
      margin: "20px 0",
      backgroundColor: "#007bff",
      color: "white",
      border: "none",
      borderRadius: "5px",
      cursor: "pointer",
    },
  };

  const onUnload = (event) => {
    console.log("page Refreshed");
    localStorage.removeItem("reminders");
  };

  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
  }, []);

  useEffect(() => {
    if (patientID) {
      dispatch(getRecordTypeAction(patientID));
      fetchPatientProfile(patientID);
    }
  }, [patientID, dispatch]);

  useEffect(() => {
    const isSummaryFilled =
      assessmentResponse?.differential_diagnosis?.trim().length > 0;
    const isNextStepsFilled = assessmentResponse?.next_steps?.trim().length > 0;

    setIsGenerateEnabled(isSummaryFilled && isNextStepsFilled);
  }, [
    assessmentResponse?.differential_diagnosis,
    assessmentResponse?.next_steps,
  ]);

  useEffect(() => {
    if (recordTypes && recordTypes?.length) {
      const excludeRecordTypes = [
        "blood_pressure",
        "blood_sugar",
        "pain_level",
        "temperature",
        "weight",
        "notes",
      ];

      const updatedRecordTypes = recordTypes
        .filter((record) => !excludeRecordTypes.includes(record.record_type))
        .map((record) => {
          return record;
        });
      setRecordTypesArray(updatedRecordTypes);
    }
  }, [recordTypes]);

  const { basic_details } = patientProfile || {};
  const healthHistory = patientProfile?.health_history;

  const formatHealthHistory = (healthHistoryObj) => {
    const formattedEntries = Object.entries(healthHistoryObj)
      .filter(([key, value]) => value !== null)
      .map(([key, value]) => `${key}: ${value}`);
    if (formattedEntries.length === 0) {
      return "";
    }
    return formattedEntries.join(", ");
  };

  const fetchObservationTypes = async () => {
    try {
      const response = await dispatch(getObservationTypesAction()).unwrap();
      const newObservationTypes = response?.result;
      console.log(newObservationTypes, "----->>>> newwwwww");
      setObservationTypesArray(newObservationTypes);
    } catch (error) {
      console.error("Failed to fetch observation types:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchObservationTypes();
  }, []);

  useEffect(() => {
    setPatientID(location.state?.id);
    dispatch(resetState());
    dispatch(resetAiSummaries());
  }, [location, dispatch]);

  useEffect(() => {
    if (basic_details) {
      setPatientAge(calculateAge(basic_details?.dob || ""));
      setGender(basic_details?.gender || "");
      setPatientName(
        `${basic_details.first_name || ""} ${
          basic_details.last_name || ""
        }`.trim()
      );
    }
  }, [basic_details]);

  useEffect(() => {
    if (healthHistory) {
      setPatientHistory(formatHealthHistory(healthHistory));
    }
  }, [healthHistory]);

  useEffect(() => {
    setDifferentialDiagnosis(assessmentResponse?.differential_diagnosis || "");
    setNextSteps(assessmentResponse?.next_steps || "");
  }, [assessmentResponse]);

  useEffect(() => {
    const isSummaryFilled = differentialDiagnosis.trim().length > 0;
    const isNextStepsFilled = nextSteps.trim().length > 0;

    setIsGenerateEnabled(isSummaryFilled && isNextStepsFilled);
  }, [differentialDiagnosis, nextSteps]);

  useEffect(() => {
    if (documentData && shouldOpenDocument) {
      const blob = new Blob([documentData], { type: "application/pdf" });
      setPdfBlob(blob);

      const blobUrl = URL.createObjectURL(blob);
      window.open(blobUrl, "_blank");

      setShouldOpenDocument(false);
    }
  }, [documentData, shouldOpenDocument]);

  const fetchPatientProfile = async (id) => {
    try {
      await dispatch(getPatientProfileAction(id));
    } catch (error) {
      console.error("Error fetching patient profile:", error);
    }
  };

  // useEffect(() => {
  //   setSingleRecordResponse(aiSummaries);
  // }, [aiSummaries]);

  useEffect(() => {
    getLocalReminders();
  }, []);

  const getLocalReminders = () => {
    let existingReminders = localStorage.getItem("reminders")
      ? JSON.parse(localStorage.getItem("reminders"))
      : [];
    console.log("add reminder", existingReminders);
    setLocalReminders(existingReminders);
  };

  const onAddRecords = async (newSelectedRecords) => {
    setShowAddRecordsModal(false);
    const filesToFetch = newSelectedRecords.filter(
      (newRecord) =>
        !selectedFiles.some((existingFile) => existingFile.id === newRecord.id)
    );

    setSelectedFiles((prev) => [...prev, ...filesToFetch]);
    setIsSummaryLoading(true);

    const promises = filesToFetch.map((record) => {
      return dispatch(
        getSingleRecordAction({
          patientId: patientID,
          recordType: record?.record_type,
          recordId: record?.id,
        })
      );
    });

    try {
      await Promise.all(promises);
    } catch (error) {
      console.error("Error fetching selected records:", error);
    } finally {
      setIsSummaryLoading(false);
    }
  };

  const onUploadAudio = () => {
    setshowAudioRecordModal(false);
  };

  const callHemebotApi = async (
    query,
    currentChatHistory,
    initializeChat = false
  ) => {
    const newUserMessageEntry = {
      role: "user",
      content: query,
    };

    const updatedChatHistory = initializeChat
      ? [newUserMessageEntry]
      : [...currentChatHistory, newUserMessageEntry];
    let payload = null;
    if (initializeChat) {
      payload = {
        chats: updatedChatHistory,
      };
    } else {
      payload = {
        chats: updatedChatHistory,
        system_prompt: systemPrompt,
      };
    }
    try {
      const actionResult = await dispatch(callHemeBotAction(payload));
      const response = actionResult.payload?.data;
      if (response?.chatbot?.content && response?.system_prompt) {
        setChatHistory((prevChatHistory) => [
          ...prevChatHistory,
          {
            role: "assistant",
            content: response.chatbot.content,
          },
        ]);
        setSystemPrompt(response.system_prompt);
      }
      setUserSentMessage(true);
    } catch (error) {
      console.error("Error calling HemeBot:", error);
    }
  };

  const onRunAssessment = async () => {
    setIsLoading(true);

    const payloadAssessment = {
      patient_id: patientID,
      age: parseFloat(patientAge),
      gender: gender,
      symptoms: symptoms,
      physical_examination: physicalExamination,
      vital_readings: `Blood Pressure: ${bloodPressure}mm Hg, Heart Rate: ${heartRate}/min, SPO2: ${spo2}%, Respiratory Rate: ${respiratoryRate}/ min, Temperature: ${temperature}F, Random Blood Sugar: ${randomBloodSugar}mg/dL`,
      doctor_notes: otherNotes,
      health_history: patientHistory,
      ai_summary: singleRecordResponse,
    };
    const response = await dispatch(runAssessmentAction(payloadAssessment));
    setAssessmentResponse(response?.payload?.result);
    const reportSummary = singleRecordResponse
      .map((file, index) => `Report-${index + 1}: ${formattedData(file)}`)
      .join(", ");
    const prefillMessage = `Age: ${patientAge}, Gender: ${gender}, Patient History: ${patientHistory}, Symptoms: ${symptoms}, Physical Examination: ${physicalExamination}, Doctor Notes: ${otherNotes}, ${reportSummary}`;
    callHemebotApi(prefillMessage, chatHistory, true);
    setAssessmentRun(true);
    setIsLoading(false);
  };

  const handleRemoveReport = (reportId) => {
    const newSelectedFiles = selectedFiles.filter(
      (file) => file.id !== reportId
    );
    setSelectedFiles(newSelectedFiles);
  };

  useEffect(() => {
    setSingleRecordResponse([]);
  }, []);

  const handleNewUserMessage = useCallback(
    (newMessage) => {
      callHemebotApi(newMessage, chatHistory);
    },
    [chatHistory]
  );

  const openPdfInNewTab = (file) => {
    const blobUrl = URL.createObjectURL(file);
    window.open(blobUrl, "_blank");
  };

  const postTranscriptCallback = (data) => {
    if (data?.infoResponse?.status === "success") {
      setPatientDetails(data?.infoResponse?.result);
      const { patient_info, reminders, summary } = data?.infoResponse?.result;
      const allReminders = [...localreminders, ...reminders];
      setLocalReminders(allReminders);

      if (!!data?.transcript?.length) {
        setTranscriptionResponse(
          data?.transcript || transcriptionResponse || ""
        );
      }
      if (!!patient_info?.patient_diagnosis?.other_notes?.length) {
        setOtherNotes(
          patient_info?.patient_diagnosis?.other_notes || otherNotes || ""
        );
      }
      if (!!patient_info?.patient_diagnosis?.patient_history?.length) {
        setPatientHistory(
          patient_info?.patient_diagnosis?.patient_history ||
            patientHistory ||
            ""
        );
      }
      if (!!patient_info?.patient_diagnosis?.physical_examination?.length) {
        setPhysicalExamination(
          patient_info?.patient_diagnosis?.physical_examination ||
            physicalExamination ||
            ""
        );
      }
      if (!!patient_info?.patient_diagnosis?.symptoms?.length) {
        setSymptoms(
          patient_info?.patient_diagnosis?.symptoms || symptoms || ""
        );
      }
      if (!!patient_info?.personal_information?.age?.length) {
        setPatientAge(
          patient_info?.personal_information?.age || patientAge || ""
        );
      }
      if (!!patient_info?.personal_information?.gender?.length) {
        setGender(patient_info?.personal_information?.gender || gender || "");
      }
      if (!!patient_info?.personal_information?.height?.length) {
        setPatientHeight(
          patient_info?.personal_information?.height || patientHeight || ""
        );
      }
      if (!!patient_info?.personal_information?.weight?.length) {
        setPatientWeight(
          patient_info?.personal_information?.weight || patientWeight || ""
        );
      }
      if (!!patient_info?.recommended_next_steps?.tests_recommended?.length) {
        setNextSteps(
          patient_info?.recommended_next_steps?.tests_recommended ||
            nextSteps ||
            ""
        );
      }
      if (!!summary?.length) {
        setDifferentialDiagnosis(summary || differentialDiagnosis || "");
      }
      if (!!patient_info?.vitals?.blood_pressure?.length) {
        setBloodPressure(
          patient_info?.vitals?.blood_pressure || bloodPressure || ""
        );
      }
      if (!!patient_info?.vitals?.heart_rate?.length) {
        setHeartRate(patient_info?.vitals?.heart_rate || heartRate || "");
      }
      if (!!patient_info?.vitals?.random_bloodSugar?.length) {
        setRandomBloodSugar(
          patient_info?.vitals?.random_bloodSugar || randomBloodSugar || ""
        );
      }
      if (!!patient_info?.vitals?.respiratory_rate?.length) {
        setRespiratoryRate(
          patient_info?.vitals?.respiratory_rate || respiratoryRate || ""
        );
      }
      if (!!patient_info?.vitals?.spo2?.length) {
        setSpo2(patient_info?.vitals?.spo2 || spo2 || "");
      }
      if (!!patient_info?.vitals?.temperature?.length) {
        setTemperature(patient_info?.vitals?.temperature || temperature || "");
      }
    }
  };

  const handleDownloadClick = async () => {
    const optionsSummary = {
      margin: [10, 0],
      filename: "summary.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    const optionsTranscription = {
      margin: [10, 0],
      filename: "transcription.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };
    const optionsPrescription = {
      margin: [10, 0],
      filename: "prescription.pdf",
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2, logging: true, dpi: 192, letterRendering: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    setIsPdfMode(true);
    const summaryBlob = await html2pdf()
      .from(summaryRef.current)
      .set(optionsSummary)
      .outputPdf("blob");
    const transcriptionBlob = await html2pdf()
      .from(transcriptionRef.current)
      .set(optionsTranscription)
      .outputPdf("blob");
    const prescriptionBlob = await html2pdf()
      .from(prescriptionRef.current)
      .set(optionsPrescription)
      .outputPdf("blob");
    const summaryFile = new File([summaryBlob], "summary.pdf", {
      type: "application/pdf",
    });
    const transcriptionFile = new File(
      [transcriptionBlob],
      "transcription.pdf",
      { type: "application/pdf" }
    );
    const prescriptionFile = new File([prescriptionBlob], "prescription.pdf", {
      type: "application/pdf",
    });

    setPdfFiles({
      summary: summaryFile,
      transcription: transcriptionFile,
      prescription: prescriptionFile,
    });
    openPdfInNewTab(summaryFile);
    openPdfInNewTab(transcriptionFile);
    openPdfInNewTab(prescriptionFile);

    summaryRef.current.style.display = "none";
    transcriptionRef.current.style.display = "none";
    prescriptionRef.current.style.display = "none";
    setShowSendRecordsModal(true);
  };

  const onAudioRecord = async () => {
    setshowAudioRecordModal(true)
    // navigate('/deepgram')
  };

  const onSendRecords = () => {};

  const renderLeftSide = () => (
    <Styled.LeftSide>
      <Styled.MainInfoContainer>
        <Styled.PersonalInfoContainer>
          <Styled.ListSection>
            <Styled.ListDateTitle>
              <Styled.SideIcon src={IPersonalInfo}></Styled.SideIcon>
              <Styled.HeadingText>Personal Information</Styled.HeadingText>
            </Styled.ListDateTitle>
          </Styled.ListSection>
          <Styled.InputWrapper>
            <Styled.LabelAndInput>
              <Styled.Label>Patient Age</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.Input
                  placeholder="Enter age"
                  value={patientAge}
                  onChange={(e) => setPatientAge(e.target.value)}
                />
                <Styled.UnitLabel>years</Styled.UnitLabel>
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInput>
            <Styled.LabelAndInput>
              <Styled.Label>Gender</Styled.Label>
              <Styled.Dropdown
                value={gender}
                onChange={(e) => setGender(e.target.value)}
              >
                <option value="" disabled>
                  Select
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </Styled.Dropdown>
            </Styled.LabelAndInput>
          </Styled.InputWrapper>
          <Styled.InputWrapper>
            <Styled.LabelAndInput>
              <Styled.Label>Height</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.Input
                  value={patientHeight}
                  onChange={(e) => setPatientHeight(e.target.value)}
                />
                <Styled.UnitLabel>cms</Styled.UnitLabel>
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInput>

            <Styled.LabelAndInput>
              <Styled.Label>Weight</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.Input
                  value={patientWeight}
                  onChange={(e) => setPatientWeight(e.target.value)}
                />
                <Styled.UnitLabel>kgs</Styled.UnitLabel>
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInput>
          </Styled.InputWrapper>
        </Styled.PersonalInfoContainer>
        <Styled.PersonalInfoContainer>
          <Styled.ListSection>
            <Styled.ListDateTitle>
              <Styled.SideIcon src={IVitals}></Styled.SideIcon>
              <Styled.HeadingText>Vitals</Styled.HeadingText>
            </Styled.ListDateTitle>
          </Styled.ListSection>
          <Styled.InputWrapper>
            <Styled.LabelAndInputVitals>
              <Styled.Label>Blood Pressure</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={bloodPressure}
                  onChange={(e) => setBloodPressure(e.target.value)}
                />
                <Styled.UnitLabel>mm Hg</Styled.UnitLabel>
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
            <Styled.LabelAndInputVitals>
              <Styled.Label>Heart Rate</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={heartRate}
                  onChange={(e) => setHeartRate(e.target.value)}
                />
                <Styled.UnitLabel>/ min</Styled.UnitLabel>
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
            <Styled.LabelAndInputVitals>
              <Styled.Label>SPO2</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={spo2}
                  onChange={(e) => setSpo2(e.target.value)}
                />
                <Styled.UnitLabel>%</Styled.UnitLabel>
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
            <Styled.LabelAndInputVitals>
              <Styled.Label>Respiratory Rate</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={respiratoryRate}
                  onChange={(e) => setRespiratoryRate(e.target.value)}
                />
                <Styled.UnitLabel>/ min</Styled.UnitLabel>
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
            <Styled.LabelAndInputVitals>
              <Styled.Label>Temperature</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={temperature}
                  onChange={(e) => setTemperature(e.target.value)}
                />
                <Styled.UnitLabel>F</Styled.UnitLabel>
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
            {/* <Styled.LabelAndInputVitals>
                <Styled.Label>Temperature</Styled.Label>
                <Styled.InputUnitWrapper>
                    <Styled.InputVitals 
                        value={temperature} 
                        onChange={(e) => setTemperature(e.target.value)} 
                    />
                    <Styled.TemperatureDropdown 
                        value={temperatureUnit} 
                        onChange={(e) => setTemperatureUnit(e.target.value)}>
                        <option value="F">°F</option>
                        <option value="C">°C</option>
                    </Styled.TemperatureDropdown>
                </Styled.InputUnitWrapper>
              </Styled.LabelAndInputVitals> */}

            <Styled.LabelAndInputVitals>
              <Styled.Label>Random Blood Sugar</Styled.Label>
              <Styled.InputUnitWrapper>
                <Styled.InputVitals
                  value={randomBloodSugar}
                  onChange={(e) => setRandomBloodSugar(e.target.value)}
                />
                <Styled.UnitLabel>mg/dL</Styled.UnitLabel>
              </Styled.InputUnitWrapper>
            </Styled.LabelAndInputVitals>
          </Styled.InputWrapper>
        </Styled.PersonalInfoContainer>
      </Styled.MainInfoContainer>

      <Styled.PersonalInfoContainer>
        <Styled.ListSection>
          <Styled.ListDateTitle>
            <Styled.SideIcon src={IDiagnosis}></Styled.SideIcon>
            <Styled.HeadingText>Patient Diagnosis</Styled.HeadingText>
          </Styled.ListDateTitle>
        </Styled.ListSection>
        <Styled.InputWrapper>
          <Styled.TextAreaWrapper>
            <Styled.Label>Patient History</Styled.Label>
            <Styled.LabelTextArea>
              <Styled.TextArea
                placeholder="Enter patient history..."
                value={patientHistory}
                onChange={(e) => setPatientHistory(e.target.value)}
              />
            </Styled.LabelTextArea>
          </Styled.TextAreaWrapper>
          <Styled.TextAreaWrapper>
            <Styled.Label>Symptoms</Styled.Label>
            <Styled.LabelTextArea>
              <Styled.TextArea
                placeholder="Enter symptoms..."
                value={symptoms}
                onChange={(e) => setSymptoms(e.target.value)}
              />
            </Styled.LabelTextArea>
          </Styled.TextAreaWrapper>
        </Styled.InputWrapper>
        <Styled.InputWrapper>
          <Styled.TextAreaWrapper>
            <Styled.Label>Physical Examination</Styled.Label>
            <Styled.LabelTextArea>
              <Styled.TextArea
                placeholder="Enter physical examination details..."
                value={physicalExamination}
                onChange={(e) => setPhysicalExamination(e.target.value)}
              />
            </Styled.LabelTextArea>
          </Styled.TextAreaWrapper>
          <Styled.TextAreaWrapper>
            <Styled.Label>Other Notes</Styled.Label>
            <Styled.LabelTextArea>
              <Styled.TextArea
                placeholder="Enter other notes..."
                value={otherNotes}
                onChange={(e) => setOtherNotes(e.target.value)}
              />
            </Styled.LabelTextArea>
          </Styled.TextAreaWrapper>
        </Styled.InputWrapper>
      </Styled.PersonalInfoContainer>

      {/* <Styled.PersonalInfoContainer>
        <Styled.ListSection>
          <Styled.ListDateTitle>
            <Styled.SideIcon src={IAttachReports}></Styled.SideIcon>
            <Styled.HeadingText>Attach Reports</Styled.HeadingText>
          </Styled.ListDateTitle>
        </Styled.ListSection>
        <Styled.InputWrapper>
          <Styled.LabelTextAreaReports>
            <Styled.LabelAttachReports>
              Attach Reports
            </Styled.LabelAttachReports>
            <Styled.ReportContainer>
              <Styled.ReportList>
                {selectedFiles.map((file, index) => (
                  <Styled.ReportDetailsContainer key={file.id}>
                    <Styled.ReportNumber>
                      Report-{index + 1}
                    </Styled.ReportNumber>
                    <Styled.ReportName>{file.name}</Styled.ReportName>
                    <Styled.ArrowBackImage
                      src={IRemoveIcon}
                      alt="remove-icon"
                      onClick={() => handleRemoveReport(file.id)}
                    />
                  </Styled.ReportDetailsContainer>
                ))}
              </Styled.ReportList>
              <Styled.AttachIconImgContainer
                onClick={() => setShowAddRecordsModal(true)}
              >
                <Styled.AttachIconImg src={IAttachIcon} />
              </Styled.AttachIconImgContainer>
            </Styled.ReportContainer>
          </Styled.LabelTextAreaReports>
          <Styled.LabelAndInput>
            <Styled.Label>Reports Summary</Styled.Label>
            <Styled.TextAreaAISummary>
              {isSummaryLoading && <ShimmerText />}
              {!isSummaryLoading &&
                singleRecordResponse.map((file, index) => (
                  <Styled.ReportNumberRight key={index}>
                    {`Report-${index + 1}: ${formattedData(file)}`}
                  </Styled.ReportNumberRight>
                ))}
            </Styled.TextAreaAISummary>
          </Styled.LabelAndInput>
        </Styled.InputWrapper>
      </Styled.PersonalInfoContainer> */}

      <Styled.PersonalInfoContainer>
        <Styled.ListSection>
          <Styled.ListDateTitle>
            <Styled.SideIcon src={ITranscription}></Styled.SideIcon>
            <Styled.HeadingText>Transcription</Styled.HeadingText>
          </Styled.ListDateTitle>
        </Styled.ListSection>
        <Styled.InputWrapper>
          <Styled.TranscriptionextArea
            defaultValue={transcriptionResponse || ""}
          />
        </Styled.InputWrapper>
      </Styled.PersonalInfoContainer>
    </Styled.LeftSide>
  );

  return (
    <Styled.Container>
      <Styled.HeaderWrapper>
        <Styled.TopHeader>
          <Styled.ArrowBackImage
            src={IBackArrow}
            alt="back-arrow"
            onClick={() => {
              navigate(-1);
            }}
          />
          New Encounter
        </Styled.TopHeader>
        <Styled.AudioButtonWrapper>
          <Styled.RecordButton onClick={onAudioRecord}>
            {isTranscriptionProgress === "inProgress" ? (
              <Loader color="inherit" />
            ) : (
              <>
                <Styled.SideIcon src={IMicIcon} alt="Mic Icon" />
                Audio Scribe
              </>
            )}
          </Styled.RecordButton>
        </Styled.AudioButtonWrapper>
      </Styled.HeaderWrapper>
      <Styled.MainContent>{renderLeftSide()}</Styled.MainContent>
      <Styled.PersonalInfoContainer>
        <Styled.ListSection>
          <Styled.ListDateTitle>
            <Styled.SideIcon src={IConsultation}></Styled.SideIcon>
            <Styled.HeadingText>Consultation Summary</Styled.HeadingText>
          </Styled.ListDateTitle>
        </Styled.ListSection>
        <Styled.InputWrapper>
          <Styled.AssessmentTextArea
            value={differentialDiagnosis}
            onChange={(e) => setDifferentialDiagnosis(e.target.value)}
          />
        </Styled.InputWrapper>
      </Styled.PersonalInfoContainer>
      <Styled.PersonalInfoContainer>
        <Styled.ListSection>
          <Styled.ListDateTitle>
            <Styled.SideIcon src={INextSteps}></Styled.SideIcon>
            <Styled.HeadingText>Recommended Next Steps</Styled.HeadingText>
          </Styled.ListDateTitle>
        </Styled.ListSection>
        <Styled.InputWrapper>
          <Styled.AssessmentTextArea
            value={nextSteps}
            onChange={(e) => setNextSteps(e.target.value)}
          />
        </Styled.InputWrapper>
      </Styled.PersonalInfoContainer>
      <Styled.PersonalInfoContainer>
        <Styled.ListSection>
          <Styled.ListDateTitle>
            <Styled.HeadTitleSection>
              <Styled.ListDateTitle>
                <Styled.SideIcon src={IRemindersIcon}></Styled.SideIcon>
                <Styled.HeadingText>Reminders</Styled.HeadingText>
              </Styled.ListDateTitle>
              <div>
                <Styled.SubmitButton
                  onClick={() => {
                    navigate("consultation/add-reminder");
                  }}
                  type="submit"
                >
                  Add Reminder
                </Styled.SubmitButton>
              </div>
            </Styled.HeadTitleSection>
          </Styled.ListDateTitle>
        </Styled.ListSection>

        <Styled.ReminderCardBox>
          <Styled.ButtonBox></Styled.ButtonBox>
          {/* {patientDetails?.reminders?.length > 0 &&
            patientDetails?.reminders.map((reminder, index) => (
              <Styled.ReminderCard>
                <CustomReminderCard
                  reminder={reminder}
                  icon={observationTypesArray?.find((type) => {
                    return type.record_type == reminder.reminder_type?.type;
                  })}
                />
              </Styled.ReminderCard>
            ))} */}

          {localreminders?.length > 0 &&
            localreminders.map((reminder, index) => (
              <Styled.ReminderCard>
                <CustomReminderCard
                getLocalReminders={getLocalReminders}
                  index={index}
                  reminder={reminder}
                  icon={observationTypesArray?.find((type) => {
                    return type.record_type == reminder.reminder_type?.type;
                  })}
                />
              </Styled.ReminderCard>
            ))}

          {/* {patientDetails?.reminders?.length == 0 && */}
          {localreminders?.length == 0 && (
            <EmptyListMessage>No reminders found</EmptyListMessage>
          )}
        </Styled.ReminderCardBox>
      </Styled.PersonalInfoContainer>
      <Styled.ButtonWrapper>
        <Styled.GenerateButton
          onClick={handleDownloadClick}
          disabled={!isGenerateEnabled}
        >
          Confirm & Generate Documents
        </Styled.GenerateButton>
      </Styled.ButtonWrapper>
      <AttachRecordsModal
        open={showAddRecordsModal}
        handleClose={() => setShowAddRecordsModal(false)}
        handleSave={onAddRecords}
        alreadySelectedFiles={selectedFiles}
        recordTypesArray={recordTypesArray}
      />
      {showAudioRecordModal && (
        <AudioRecordModal
          open={showAudioRecordModal}
          handleClose={() => setshowAudioRecordModal(false)}
          handleSave={onUploadAudio}
          setPhysicalExamination={setPhysicalExamination}
          setOtherNotes={setOtherNotes}
          setPatientHistory={setPatientHistory}
          setSymptoms={setSymptoms}
          physicalExamination={physicalExamination}
          otherNotes={otherNotes}
          patientHistory={patientHistory}
          symptoms={symptoms}
          setTranscriptionResponse={setTranscriptionResponse}
          setIsTranscriptionProgress={setIsTranscriptionProgress}
          isTranscriptionProgress={isTranscriptionProgress}
          postTranscriptCallback={postTranscriptCallback}
        />
      )}
      <AddRecords
        open={showSendRecordsModal}
        handleClose={() => setShowSendRecordsModal(false)}
        handleSave={onSendRecords}
        pdfFiles={pdfFiles}
      />
      <SnackBar />
      <div ref={summaryRef} style={contentStyle}>
        <header style={styles.header}>
          <div style={styles.leftWrapper}>
            <h1 style={styles.title}>Dr. HemeHealth</h1>
            <p style={styles.subTitle}>MBBS, MD, General Physician</p>
          </div>
          <img src={ILogoWhite} />
        </header>
        <h2 style={styles.sectionTitle}>Consultation Summary</h2>
        <div style={styles.consultationSummary}>
          <div style={styles.contentBlock}>
            <strong>Name : </strong>
            {patientName}
          </div>
          <div style={styles.personalInfoWrapper}>
            <div style={styles.contentBlock}>
              <strong>Age : </strong> {patientAge}
            </div>
            <div style={styles.contentBlock}>
              <strong>Gender : </strong> {gender}
            </div>
            <div style={styles.contentBlock}>
              <strong>Height : </strong>
              {patientHeight}
            </div>
            <div style={styles.contentBlock}>
              <strong>Weight : </strong>
              {patientWeight}
            </div>
          </div>
          <div style={styles.personalInfoWrapper}>
            <div style={styles.contentBlock}>
              <strong>Blood Pressure : </strong> {bloodPressure}
            </div>
            <div style={styles.contentBlock}>
              <strong>Heart Rate : </strong> {heartRate}
            </div>
            <div style={styles.contentBlock}>
              <strong>SPO2 : </strong>
              {spo2}
            </div>
            <div style={styles.contentBlock}>
              <strong>Respiratory Rate : </strong>
              {respiratoryRate}
            </div>
            <div style={styles.contentBlock}>
              <strong>Temperature : </strong>
              {temperature}
            </div>
            <div style={styles.contentBlock}>
              <strong>Random Blood Sugar : </strong>
              {randomBloodSugar}
            </div>
          </div>
          <div style={styles.contentBlock}>
            <strong>Patient's History : </strong> {patientHistory}
          </div>
          <div style={styles.contentBlock}>
            <strong>Symptoms :</strong> {symptoms}
          </div>
          <div style={styles.contentBlock}>
            <strong>Physical Examination : </strong> {physicalExamination}
          </div>
          <div style={styles.contentBlock}>
            <strong>Doctor Notes : </strong> {otherNotes}
          </div>
        </div>
      </div>

      <div ref={transcriptionRef} style={contentStyle}>
        <header style={styles.header}>
          <div style={styles.leftWrapper}>
            <h1 style={styles.title}>Dr. HemeHealth</h1>
            <p style={styles.subTitle}>MBBS, MD, General Physician</p>
          </div>
          <img src={ILogoWhite} />
        </header>
        <h2 style={styles.sectionTitle}>Transcription</h2>
        <div style={styles.consultationSummary}>
          <div style={styles.contentBlock}>{transcriptionResponse}</div>
        </div>
      </div>

      <div ref={prescriptionRef} style={contentStyle}>
        <header style={styles.header}>
          <div style={styles.leftWrapper}>
            <h1 style={styles.title}>Dr. HemeHealth</h1>
            <p style={styles.subTitle}>MBBS, MD, General Physician</p>
          </div>
          <img src={ILogoWhite} />
        </header>
        <h2 style={styles.sectionTitle}>Prescription</h2>
        <div style={styles.consultationSummary}>
          <div style={styles.contentBlock}>
            <strong>Consultation Summary : </strong> {differentialDiagnosis}
          </div>
          <div style={styles.contentBlock}>
            <strong>Recommended Next Steps : </strong> {nextSteps}
          </div>
        </div>
      </div>
      <ChatWidgetComponent
        handleNewUserMessage={handleNewUserMessage}
        userSentMessage={userSentMessage}
        assessmentRun={assessmentRun}
      />
    </Styled.Container>
  );
};
export default NewEncounter;
