import React, { useState } from "react";

import { Box, Modal } from "@mui/material";
import {
  CloseButton,
  CloseButtonWrapper,
  ModalBody,
  ModalHeader,
  ButtonWrapper,
  SaveButton,
  NoButton,
  DeleteText,
} from "./style.components";
import { ICloseModal } from "../../../../assets";

const DeleteConsultationsModal = ({
  confirmModalOpen,
  setConfirmModalOpen,
  deleteId,
  getLocalReminders,
}) => {
  const style = {
    position: "relative",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "40%",
    height: "220px",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: "12px",
    "@media(min-width:991px)": {
      minWidth: "550px",
    },
    "@media(max-width:991px)": {
      width: "75%",
    },
    "@media(max-width:768px)": {
      width: "85%",
    },
    "@media(max-width:600px)": {
      width: "95%",
    },
  };

  const onDeleteItem = () => {
    let existingReminders = localStorage.getItem("reminders")
      ? JSON.parse(localStorage.getItem("reminders"))
      : [];

    let localreminders = [...existingReminders];
    localreminders.splice(deleteId, 1);
    localStorage.setItem("reminders", JSON.stringify(localreminders));
    setConfirmModalOpen(false);
    getLocalReminders();
  };

  return (
    <>
      <Modal
        open={confirmModalOpen}
        onClose={() => setConfirmModalOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <CloseButtonWrapper>
            <CloseButton
              src={ICloseModal}
              onClick={() => setConfirmModalOpen(false)}
            />
          </CloseButtonWrapper>
          <ModalHeader id="modal-modal-title">Are You Sure ?</ModalHeader>
          <ModalBody>
            <DeleteText>This action cannot be undone once deleted.</DeleteText>
          </ModalBody>
          <ButtonWrapper>
            <SaveButton onClick={() => onDeleteItem()}> Delete </SaveButton>
            <NoButton onClick={() => setConfirmModalOpen(false)}>
              {" "}
              Cancel{" "}
            </NoButton>
          </ButtonWrapper>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteConsultationsModal;
