import { Box } from "@mui/material";
import {
  CardDetailsWrapper,
  ListItemDate,
  ListItemWrapper,
  ThumbImage,
  ReportType,
  ReportTypeWrapper,
  ContentType,
  ThumbImageWrapper,
} from "../../../containers/view-patient/patient-records/style.components";
import styled from "styled-components";
import DropdwonThreeDotMenu from "../DropdownThreeDot";
import { formatTimestamp } from "../../../utilities/common";

const ListItemTopReport = styled.div`
  color: #404040;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-height: 15px;
  flex: 1 1 calc(20% - 24px);
  max-width: 140px;
  padding-left: 5px;
`;

const SingleReport = ({ name, icon, test_type, upload_date }) => {
  return (
    <Box
      sx={{
        background: "#FFF",
        padding: "1rem",
        borderRadius: "1rem",
        border: "0.5rem solid #D7EFF7",
      }}
      display="flex"
      justifyContent="center"
    >
      <ListItemWrapper style={{ cursor: "auto" }}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
          mb={1}
        >
          <ListItemTopReport>{name}</ListItemTopReport>
          <DropdwonThreeDotMenu menuItems={[]} />
        </Box>
        <ThumbImageWrapper>
          <ThumbImage src={icon} alt="records-thumbnail" />
          <ReportTypeWrapper>
            <ReportType>{test_type || "REPORT"}</ReportType>
            <ContentType>PDF</ContentType>
          </ReportTypeWrapper>
        </ThumbImageWrapper>
        <CardDetailsWrapper>
          <ListItemDate>{formatTimestamp(upload_date)}</ListItemDate>
        </CardDetailsWrapper>
      </ListItemWrapper>
    </Box>
  );
};

export default SingleReport;
