import styled from "styled-components";
import {
  FOOTER_HEIGHT,
  HEADER_HEIGHT,
} from "../../../utilities/constants";

export const Container = styled.div`
    margin: 40px;
    padding: 24px;
    border-radius: 12px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 32px #2477b314;
    opacity: 1;
    min-height: calc(100vh - ${HEADER_HEIGHT}px - ${FOOTER_HEIGHT});
    margin-bottom: 0;
`;
export const TopHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    color: #0099CC;
    margin-bottom: 28px;
`;
export const ArrowBackImage = styled.img`
    cursor: pointer;
`;
export const TypeContainer = styled.div`
    background-color: #F5FBFC;
    border-radius: 8px;
    padding: 28px 24px;
`;
export const TypeText = styled.div`
    color: #000000;
    font-size: 20px;
    margin-bottom: 28px;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
export const FormElementContainer = styled.div`
    display: flex;
    flex-direction: column;
`;
export const FormQuestion = styled.label`
    font-size: 13px;
    color: #808080;
    margin-bottom: 12px;
`;
export const InputAnswer = styled.input`
    font-size: 16px;
    color: #000000;
    padding: 10px 15px;
    border-radius: 12px;
    border: 1px solid #92D4E9;
    width: 330px;
`;
export const InputContainer = styled.div`
    display: flex;
    flex-direction: ${props => props.columnFlex ? 'column' : 'row'};
`;
export const RadioContainer = styled.div`
    padding: 7px 15px;
    background-color: ${props => props.isSelected ? '#EBF7FB' : '#FFFFFF'};
    border-radius: 12px;
    border: 1px solid #92D4E9;
    margin-right: 10px;
    cursor: pointer;
    width: ${props => props.columnFlex ? '330px' : 'unset'};

    &:first-child {
        border-bottom-left-radius: ${props => props.columnFlex ? '0' : '12px'};
        border-bottom-right-radius: ${props => props.columnFlex ? '0' : '12px'};
    }

    &:last-child {
        border-top-left-radius: ${props => props.columnFlex ? '0' : '12px'};
        border-top-right-radius: ${props => props.columnFlex ? '0' : '12px'};
        border-top: ${props => props.columnFlex ? '0' : '1px solid #92D4E9'};
    }

    &:not(:first-child):not(:last-child) {
        border-radius: ${props => props.columnFlex ? '0' : '12px'};
        border-top: ${props => props.columnFlex ? '0' : '1px solid #92D4E9'};
    }
`;
export const RadioAnswer = styled.input`
    margin-right: 10px;
    cursor: pointer;
`;
export const RadioLabel = styled.label`
    font-size: 16px;
    color: ${props => props.type === 'radio' ? '#808080' : '#000000'};
    cursor: pointer;
`;
export const CheckboxContainer = styled.div`
    padding: 7px 15px;
    background-color: ${props => props.isSelected ? '#EBF7FB' : '#FFFFFF'};
    border-radius: 12px;
    border: 1px solid #92D4E9;
    margin-right: 10px;
    cursor: pointer;
    width: ${props => props.columnFlex ? '330px' : 'unset'};

    &:first-child {
        border-bottom-left-radius: ${props => props.columnFlex ? '0' : '12px'};
        border-bottom-right-radius: ${props => props.columnFlex ? '0' : '12px'};
    }

    &:last-child {
        border-top-left-radius: ${props => props.columnFlex ? '0' : '12px'};
        border-top-right-radius: ${props => props.columnFlex ? '0' : '12px'};
        border-top: ${props => props.columnFlex ? '0' : '1px solid #92D4E9'};
    }

    &:not(:first-child):not(:last-child) {
        border-radius: ${props => props.columnFlex ? '0' : '12px'};
        border-top: ${props => props.columnFlex ? '0' : '1px solid #92D4E9'};
    }
`;
export const BooleanContainer = styled.div`
    padding: 7px 15px;
    background-color: ${props => props.isSelected ? '#EBF7FB' : '#FFFFFF'};
    border-radius: 12px;
    border: 1px solid #92D4E9;
    margin-right: 10px;
    cursor: pointer;
    width: 330px;
`;
export const CheckboxAnswer = styled.input`
    margin-right: 10px;
    cursor: pointer;
`;
export const CheckboxLabel = styled.label`
    font-size: 16px;
    color: ${props => props.isSelected ? '#000000' : '#808080'};
    cursor: pointer;
`;
export const DateInput = styled.input`
    width: 350px;
    padding: 10px 5px;
    border-radius: 12px;
    font-size: 16px;
    border: 1px solid #92D4E9;
    color: #000000;
    cursor: pointer;
`;
export const SaveButton = styled.button`
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    padding: 12px 50px;
    background-color: #7ABA56;
    border: none;
    width: 365px;
    cursor: pointer;
`;
export const PlainTextAnswer = styled.div`
    font-size: 16px;
    color: #000000;
    padding: 10px 15px;
    border-radius: 12px;
    border: 1px solid #92D4E9;
    width: 330px;
    background-color: #f5f5f5; /* Disabled look */
    cursor: default;
`;