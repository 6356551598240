import { API_ENDPOINT } from "../utilities/constants";
import { apiClient } from "./api-client";

const getConsultations = async (payload) => {
  try {
    const url = `${API_ENDPOINT.PATIENT}/care_episodes`;
    const response = await apiClient.post(url, payload, false);
    return response;
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export {
  getConsultations,
};
