import { Box } from "@mui/material";
import styled from "styled-components";
import { removeSpecialCharacters } from "../../utilities/common";

const FileName = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.7rem;
`;

const ListItemWrapper = styled.div`
  padding: 0px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
  width: 100%;
`;

const ThumbImages = styled.div`
  position: relative;
  background: #ebf7fa;
  display: flex;
  justify-content: start;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  height: 80px;
  align-items: center;
`;

const Button = styled.button`
  background-color: #7aba56;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  margin-right: 5px;
  border-radius: 1rem;
  font-size: 12px;
  cursor: pointer;
`;

const ThumbImage = styled.img`
  height: 100%;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 8px;
`;

const FileInfoSection = ({ recordInfo }) => {
  const screenWidth = window.innerWidth;
  const isMobileWidth = screenWidth <= 768;

  const handleScanClick = async () => {
    const url = isMobileWidth
      ? recordInfo?.mobile_viewer_url
      : recordInfo?.desktop_viewer_url;
    const newWindow = window.open("", "_blank");
    if (!newWindow) {
      alert(
        "Unable to open the new window. Please disable your popup blocker and try again."
      );
      return;
    }
    newWindow.location.href = url;
  };

  const handleScanTmtvClick = async () => {
    const url = recordInfo?.tmtv_viewer_url;
    const newWindow = window.open("", "_blank");
    if (!newWindow) {
      alert(
        "Unable to open the new window. Please disable your popup blocker and try again."
      );
      return;
    }
    newWindow.location.href = url;
  };

  return (
    <Box display="flex" alignItems="center" m={2} mt={4} mb={4}>
      <Box display="flex" alignItems="center">
        <ListItemWrapper>
          <ThumbImages>
            {recordInfo.thumbnail && (
              <ThumbImage src={recordInfo?.thumbnail} alt="records-thumbnail" />
            )}
          </ThumbImages>
        </ListItemWrapper>
      </Box>
      <Box ml={2}>
        <FileName>
          File Name: {removeSpecialCharacters(recordInfo.patient_name)}
        </FileName>
        <Box display="flex">
          <Button onClick={handleScanClick}>Basic viewer</Button>
          <Button onClick={handleScanTmtvClick}>Total Metabolic Tumor</Button>
        </Box>
      </Box>
    </Box>
  );
};

export default FileInfoSection;
