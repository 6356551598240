import styled from "styled-components";
import {
  HEADER_HEIGHT,
} from "../../../../utilities/constants";
import { Select, FormControl, OutlinedInput } from "@mui/material";

export const Container = styled.div`
  margin: 24px 48px;
  border-radius: 12px;
  background-color: #F5FBFC;
  opacity: 1;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  margin-bottom: 0;
  overflow-y: auto;
`;
export const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  color: #000000;
`;
export const ArrowBackImage = styled.img`
  cursor: pointer;
`;
export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const SectionTitle = styled.div`
  font-size: 16px;
  margin: 28px 0;
`

export const CheckboxGroup = styled.div`
  /* Styles for your checkbox group */
`;

export const DateSelection = styled.div`
  margin-bottom: 25px;
`;

export const RadioGroup = styled.div`
  /* Styles for your radio button group */
`;

export const TimeSelection = styled.div`
  /* Styles for your time selection inputs */
`;

export const TimeContainer = styled.div`
  padding: 20px 16px;
  background-color: #D7EFF7;
  border-radius: 8px;
  margin-bottom: 25px;
`;

export const DescriptionInput = styled.input`
  border-radius: 12px;
  border: 1px solid #92D4E9;
  padding: 10px 15px;
  font-size: 16px;
  color: #000000;
  margin-bottom: 25px;
`;

export const SubmitButton = styled.button`
  min-width: 180px;
  max-width: 230px;
  border-radius: 8px;
  background-color: #7ABA56;
  color: #FFFFFF;
  padding: 10px 38px;
  border: none;
  cursor: pointer;
`;

export const ObservationTypeContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 7px;
  align-items: center;
  margin-bottom: 28px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export const ObservationType = styled.div`
  border: 1px solid #2FAED7;
  border-radius: 12px;
  cursor: pointer;
  font-size: 16px;
  background-color: ${({ isSelected }) => isSelected ? '#EBF7FB' : '#FFFFFF'};
  color: ${({ isSelected }) => isSelected ? '#000000' : '#000000'};
  padding: 10px 12px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const ObservationLogo = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 5px;
`

export const SugarLevelFor = styled.div`
  margin-bottom: 35px;
`;

export const SugarLevelForText = styled.div`
  font-size: 13px;
  color: #808080;
  margin-bottom: 10px;
`;

export const SelectDateText = styled.div`
  font-size: 13px;
  color: #808080;
  margin-bottom: 10px;
`;

export const FrequencyText = styled.div`
  font-size: 13px;
  color: #808080;
  margin-bottom: 10px;
`;

export const DescriptionText = styled.div`
  font-size: 13px;
  color: #808080;
  margin-bottom: 10px;
`;

export const TimeText = styled.div`
  font-size: 16px;
  color: #000000;
  margin-bottom: 15px;
`;

export const SelectTimeText = styled.div`
  margin-top: 25px;
  font-size: 13px;
  color: #000000;
  margin-bottom: 20px;
`;

export const RadioMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const RadioButtonContainer = styled.div`
  border: 1px solid #92D4E9;
  font-size: 16px;
  color: ${({ $isSelected }) => ($isSelected ? '#000000' : '#808080')};
  border-radius: 12px;
  padding: 7px 15px;
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: ${({ $isSelected }) => ($isSelected ? '#EBF7FB' : '#FFFFFF')};
`;

export const RadioButtonLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const RadioButton = styled.input.attrs({ type: 'radio' })`
  cursor: pointer;
  accent-color: #0099CC;
  opacity: 0;
  width: 0;
  height: 0;
  position: absolute;
  margin: 0;
  padding: 0;
`;

export const DateLabel = styled.label`
  font-size: 16px;
  color: #000000;
  margin: 10px 0;
  display: block;
`;

export const DateInput = styled.input.attrs({ type: 'date' })`
  padding: 8px;
  border-radius: 12px;
  border: 1px solid #92D4E9;
  cursor: pointer;
  margin-left: 10px;
  margin-right: 20px;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const TimeInputLabel = styled.div`
  font-size: 13px;
  color: #808080;
  margin-bottom: 12px;
`;

export const TimeInput = styled.input.attrs({ type: 'time' })`
  padding: 10px 15px;
  border-radius: 12px;
  border: 1px solid #92D4E9;
  cursor: pointer;

  &:focus {
    outline: none;
    border-color: #0099CC;
  }
`;

export const TimeInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;

export const TimeLabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 54px;

  &:first-child, &:last-child {
    margin-left: 0;
  }

  &:last-child {
    margin-left: 35px;
  }
`;

export const TimeSelectionRow = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const DaySelection = styled.div`
  padding: 20px 16px;
  background-color: #D7EFF7;
  border-radius: 8px;
  margin-bottom: 25px;
`;

export const SelectDayText = styled.div`
  font-size: 16px;
  color: #000000;
  margin-bottom: 15px;
`;

export const DayContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`;

export const DayCheckboxWrapper = styled.label`
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  color: #808080;
`;

export const DayCheckbox = styled.input.attrs({ type: 'checkbox' })`
  cursor: pointer;
`;

export const CustomDateSelection = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
`;

export const CustomContainer = styled.div`
  padding: 20px 16px;
  background-color: #D7EFF7;
  border-radius: 8px;
  margin-bottom: 25px;
`;

export const SelectCustomDateText = styled.h3`
  margin-bottom: 10px;
  font-size: 16px;
`;

export const CustomDateInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CustomDateInput = styled.input.attrs({ type: 'date' })`
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

export const AddMoreText = styled.span`
  color: #7ABA56;
  font-size: 13px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const CustomSelect = styled(Select)`
  width: 30%;
  .MuiSelect-select {
    padding: 8px;
    &:focus {
      border-radius: 12px !important;
      background-color: transparent;
    }
  }
`;

export const CustomFormControl = styled(FormControl)`
  width: 100%;
  margin-bottom: 25px;
`;

export const CustomOutlinedInput = styled(OutlinedInput)`
  border-radius: 12px !important;
  border: 1px solid #92D4E9;

  &:hover {
    border: 1px solid #0099CC; // Change this color as needed for hover state
  }

  &.Mui-focused {
    border-color: #0099CC; // Change this color as needed for focus state
  }

  fieldset {
    border: none; // Hides the default border
  }

  input {
    padding: 10px 15px;
    font-size: 16px;
    color: #000000;
  }
`;

export const ObservationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  margin-top: 24px;
`;
export const ObservationSelector = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
export const ObservationTile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 16px;
  width: 311px;
  height: 52px;
  background-color: ${props => props.isSelected ? '#D7EFF7' : '#FFFFFF'};
  border-radius: 12px;
  margin-bottom: 16px;
`;

export const ObservationDetail = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
`;
export const ObservationThumb = styled.img`
  width: 40px;
  height: 40px;
`;
export const ObservationName = styled.div`
  font-size: 16px;
  color: #0099CC
`;
export const ObservationEntry = styled.div`
  background-color: #FFFFFF;
  border-radius: 12px;
  padding: 30px 48px;
  width: 100%;
  min-height: 724px;
`;
export const NextArrowIcon = styled.img`
    cursor: pointer;
    width: 24px;
    height: 24px;
`;
export const ObservationInfo = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  margin-bottom: 40px;
`;
export const ObservationLogoSmall = styled.img`
  width: 24px;
  height: 24px;
`;
export const ObservationNameSmall = styled.div`
  font-size: 16px;
  color: #000000;
`;
export const SaveButton = styled.button`
    border-radius: 8px;
    font-size: 16px;
    color: #FFFFFF;
    padding: 12px 50px;
    background-color: #7ABA56;
    border: none;
    width: 365px;
    cursor: pointer;
    margin-top: 40px;
`;